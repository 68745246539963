.card-plano-pagamento {
  background-color: $branco;
  border: 1px solid $cinza5;
}
.planos h1{
  font-weight: bold;
  font-size: 34px;
  width: fit-content;
  font-family: 'Lato', sans-serif;
}
.input-pagamento{
  border-radius: 0px !important;
}
.planos h3{
  color: #a3a3a3;
  font-weight: 700;
  display: inline;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
}
.descricao-vantagem{
  color: #a3a3a3;
  font-weight: 300;
  display: inline;
  font-size: 13px;
  font-family: 'Lato', sans-serif;

}

.planos h2{
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
.planos>div:nth-child(2) h2{
  color: #450000;
}
.icon-cross{
  color: #9e0000;
}
.icon-checkmark{
  color: #ff9322;
}
.planos>div:nth-child(3) h2{
  color: #ff9322;
}
.planos>div:nth-child(4) h2{
  color: #e2d500;
}
.planos>div:nth-child(5) h2{
  color: #870000;
}
.plano-action .btn-primary{
  width: 100%;
  border-radius: 7px;
  color:#ffffff;
}
.plano-action>div:nth-child(2) .btn-primary{
  background-color: #450000;
}
.plano-action>div:nth-child(3) .btn-primary{
  background-color: #ff9322;
}
.plano-action>div:nth-child(4) .btn-primary{
  background-color:  #e2d500;
}
.plano-action>div:nth-child(5) .btn-primary{
  background-color:  #870000;
}
.cielo-img{
  max-width: 250px;
}



.planos p, .descricao-plano{
  font-weight: 500;
  font-size: 12px;
  color: #858585;
  font-family: 'Lato', sans-serif;
}

.planos .frequencia-pagamento{
  font-size: 12px;
  display: block;
  text-align: end;
  color: #999999;
  font-weight: 200;
  margin-right: 1rem;
  font-family: 'Lato', sans-serif;
}

.card-plano-pagamento h5 {
  padding: 5px;
  font-weight: bold;
}
.card-plano-pagamento .txt-color {
  font-size: 22px;
  font-weight: bold;
}
// pct-1
.card-plano-pagamento.pct-1 h5 {
  background-color: $pct1;
}
.card-plano-pagamento.pct-1 .txt-color {
  color: $pct1;
}

// pct-2
.card-plano-pagamento.pct-2 h5 {
  background-color: $pct2;
  color: $branco;
}

.card-plano-pagamento.pct-2 .txt-color {
  color: $pct2;
}

//   pct-3
.card-plano-pagamento.pct-3 h5 {
  background-color: $pct3;
}

.card-plano-pagamento.pct-3 .txt-color {
  color: $pct3;
}

//   pct-4
.card-plano-pagamento.pct-4 h5 {
  background-color: $pct4;
  color: $branco;
}

.card-plano-pagamento.pct-4 .txt-color {
  color: $pct4;
}

///////
.card-plano-pagamento .descricao {
  min-height: 110px;
}
.card-plano-pagamento .descricao li {
  margin-top: 10px;
}

.card-plano-pagamento .btn-acao {
  padding: 20px 0px;
}
.planos-action .btn-primary {
  color:#ffffff;
}
@media  (max-width: 575px){
  .planos .plano:nth-child(1)  .plano-action .btn-primary{
    background-color: #450000;
  }
  .planos .plano:nth-child(2) .plano-action .btn-primary{
    background-color: #ff9322;
  }
  .planos .plano:nth-child(3) .plano-action .btn-primary{
    background-color:  #e2d500;
  }
  .planos .plano:nth-child(4) .plano-action .btn-primary{
    background-color:  #870000;
  }
}