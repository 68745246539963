/* ---- AJUSTES ---- */
.cardErroCpf {
  background-color: #f8f8f8;
}
.card2 {
  margin-top: -0.7em;
}

/* ---- CROP---- */

/* MOBILE */

@media (max-width: 1024px) {
}
