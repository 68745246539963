.center {
  display: block;
  margin: 0 auto;
}

.color-red {
  color: #c24040 !important;
}
.imageInner {
}
.change-picture:hover {
  opacity: 1 !important;
}

.change-picture {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  margin-top: -50px;
  position: relative;
  width: 95px;
  // background-color: rgb(219, 219, 219, 0.5);
  border-radius: 60px;
  color: black;
}

.text-change-picture {
  font-size: 14px;
  font-weight: 700;
}

.buttonImage {
  position: relative;
  margin: 10px;
}
.buttonImage img {
  object-fit: cover;
  border-radius: 6px;
}
.buttonImage.box-add-image {
  border: 1px solid $cinza1;
  border-radius: 6px;
  display: flex;
}
.buttonImage.box-add-image.error {
  border: 2px solid theme-color-level(danger, 0);
}
.buttonImage.box-add-image p {
  font-family: 'Roboto Slab', serif;
  text-align: center;
  margin: auto;
  width: 60%;
  font-size: $font-sm;
  color: $cinza1;
  line-height: 21px;
}
.overlayImage {
  position: absolute;
  cursor: pointer;
  height: 23px;
  width: 23px;
  opacity: 1;
  transition: 0.5s ease;
}
.overlayImage.addImage {
  bottom: 3px;
  right: 6px;
  background: url($icon-add-more) no-repeat center center;
}
.overlayImage.addImageCamera {
  bottom: 3px;
  left: 6px;
  background: url(./img/camera-solid.svg) no-repeat center center;
}
.overlayImage.removeImage {
  top: -11px;
  right: -11px;
  background: url(./img/bt_x.svg) no-repeat center center;
}

// .buttonImage:hover {
//   opacity: 1;
//   transform: translate(0, 10px);
// }

.icon-x {
  align-self: center;
  margin-left: 3px;
  color: #fff;
}

.changeImage {
  display: flex;
  justify-content: center;
  position: absolute;
  border-radius: 20px;
  background-color: theme-color-level(destaque, 0);
  bottom: 0;
  right: 0;
  margin: 0 10px;
  height: 40px;
  width: 40px;
  opacity: 0;
  transition: 0.5s ease;
}

.buttonImage:hover .changeImage {
  opacity: 1;
  transform: translate(0, -10px);
}

.icon-foto {
  align-self: center;
  margin-left: 3px;
  color: #fff;
}

@media (max-width: 1024px) {
  .removeImage {
    margin: -4px 6px;
    height: 24px;
    width: 24px;
  }
  .buttonImage .removeImage {
    opacity: 1;
    transform: translate(0, 10px);
  }
  .icon-x {
    font-size: 9px;
    margin-left: 0;
  }
  .changeImage {
    margin: -4px 6px;
    height: 30px;
    width: 30px;
  }
  .buttonImage .changeImage {
    opacity: 1;
    transform: translate(0, -10px);
  }
  .icon-foto {
    font-size: 14px;
    margin-left: 0;
  }
}
