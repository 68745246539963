ul.listaMensagensBox {
  list-style: none;
  padding-left: 0px;
}

ul.listaMensagensBox li {
  border-bottom: 1px solid #eef5f9;
}

ul.listaMensagensBox li a {
  display: inline-flex;
  text-decoration: none;
  font-style: normal;
  color: #252525;
  font-weight: normal;
  padding: 15px;
}

ul.listaMensagensBox li.active,
ul.listaMensagensBox li:focus,
ul.listaMensagensBox li:hover {
  background-color: #f9f9f9;
  /* border-top: 1px solid #eef5f9; */
}

ul.listaMensagensBox .listaTarefas li a.active,
ul.listaMensagensBox .listaTarefas li a:focus,
ul.listaMensagensBox .listaTarefas li a:hover {
  color: theme-color-level(danger, 2);
}

ul.listaMensagensBox .listaMensagens li a.active,
ul.listaMensagensBox .listaMensagens li a:focus,
ul.listaMensagensBox .listaMensagens li a:hover {
  color: theme-color-level(primary, 2);
}

ul.listaMensagensBox li i {
  padding: 5px 0px;
  font-size: 15px;
}

.avatar-img {
  border-radius: 100px;
  width: 50px;
  position: relative;
  top: -3px;
}

.user-avatar {
  font-size: 14px;
  font-weight: 700;
}

ul.listaMensagensBox .badge {
  position: inherit;
}

ul.listaMensagensBox .resumoMsg {
  text-align: left;
  padding: 0px 10px;
}

ul.listaMensagensBox .msg {
  font-size: 13px;
}

ul.listaMensagensBox .nomeMsg {
  font-weight: bold;
  margin-bottom: 5px;
}

ul.listaMensagensBox .dataMsg {
  min-width: 120px;
}

ul.listaMensagensBox .data {
  color: #76b956;
  font-size: 10px;
  margin-bottom: 10px;
}

ul.listaMensagensBox .setor {
  font-size: 13px;
}

ul.listaMensagensBox .localidade {
  font-size: 13px;
  margin-bottom: 10px;
}

ul.listaMensagensBox .cargo {
  font-size: 10px;
}

/* MOBILE */

@media (max-width: 1024px) {
}
