@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?y5pqi4');
  src:  url('fonts/icomoon.eot?y5pqi4#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?y5pqi4') format('truetype'),
    url('fonts/icomoon.woff?y5pqi4') format('woff'),
    url('fonts/icomoon.svg?y5pqi4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-credit-card:before {
  content: "\e963";
}
.icon-icon_x:before {
  content: "\e95f";
}
.icon-btn_confirm .path1:before {
  content: "\e960";
  color: rgb(133, 205, 99);
}
.icon-btn_confirm .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-edit_icon:before {
  content: "\e95d";
}
.icon-icon_troca:before {
  content: "\e95e";
}
.icon-cpX .path1:before {
  content: "\e951";
  color: rgb(221, 221, 221);
}
.icon-cpX .path2:before {
  content: "\e952";
  margin-left: -1.0615234375em;
  color: rgb(221, 221, 221);
}
.icon-cpX .path3:before {
  content: "\e953";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-cpX .path4:before {
  content: "\e954";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-cpX .path5:before {
  content: "\e955";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-cpX .path6:before {
  content: "\e956";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.card-seculus{
  width: 100%;
}
.icon-cpX .path7:before {
  content: "\e957";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-docX .path1:before {
  content: "\e958";
  color: rgb(221, 221, 221);
}
.icon-docX .path2:before {
  content: "\e959";
  margin-left: -1.2001953125em;
  color: rgb(0, 0, 0);
}
.icon-docX .path3:before {
  content: "\e95a";
  margin-left: -1.2001953125em;
  color: rgb(0, 0, 0);
}
.icon-docX .path4:before {
  content: "\e95b";
  margin-left: -1.2001953125em;
  color: rgb(0, 0, 0);
}
.icon-docX .path5:before {
  content: "\e95c";
  margin-left: -1.2001953125em;
  color: rgb(0, 0, 0);
}
.icon-arrowR:before {
  content: "\e950";
}
.icon-historico:before {
  content: "\e94d";
}
.icon-manutencao:before {
  content: "\e94e";
}
.icon-relogio:before {
  content: "\e94f";
}
.icon-cp:before {
  content: "\e946";
}
.icon-doc:before {
  content: "\e94c";
}
.icon-excel .path1:before {
  content: "\e944";
  color: rgb(221, 221, 221);
}
.icon-excel .path2:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(133, 205, 99);
}
.icon-badge .path1:before {
  content: "\e940";
  color: rgb(123, 189, 92);
}
.icon-badge .path2:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(133, 205, 99);
}
.icon-badge .path3:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-anexo:before {
  content: "\e93e";
}
.icon-send:before {
  content: "\e93f";
  color: #fff;
}
.icon-archive .path1:before {
  content: "\e947";
  color: rgb(0, 0, 0);
}
.icon-archive .path2:before {
  content: "\e948";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}
.icon-archive .path3:before {
  content: "\e949";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}
.icon-archive .path4:before {
  content: "\e94a";
  margin-left: -0.9580078125em;
  color: rgb(0, 0, 0);
}
.icon-archive .path5:before {
  content: "\e94b";
  margin-left: -0.9580078125em;
  color: rgb(133, 205, 99);
}
.icon-imprimir:before {
  content: "\e943";
}
.icon-acessar:before {
  content: "\e900";
}
.icon-alert_x:before {
  content: "\e901";
}
.icon-alert .path1:before {
  content: "\e902";
  color: rgb(234, 58, 69);
}
.icon-alert .path2:before {
  content: "\e903";
  margin-left: -1.599609375em;
  color: rgb(0, 0, 0);
}
.icon-alert .path3:before {
  content: "\e904";
  margin-left: -1.599609375em;
  color: rgb(0, 0, 0);
}
.icon-alert .path4:before {
  content: "\e905";
  margin-left: -1.599609375em;
  color: rgb(0, 0, 0);
}
.icon-alert .path5:before {
  content: "\e906";
  margin-left: -1.599609375em;
  color: rgb(0, 0, 0);
}
.icon-alert .path6:before {
  content: "\e907";
  margin-left: -1.599609375em;
  color: rgb(0, 0, 0);
}
.icon-clien:before {
  content: "\e908";
}
.icon-colab:before {
  content: "\e909";
}
.icon-conf:before {
  content: "\e90a";
}
.icon-config:before {
  content: "\e90b";
}
.icon-contract .path1:before {
  content: "\e90c";
  color: rgb(39, 41, 43);
}
.icon-contract .path2:before {
  content: "\e90d";
  margin-left: -0.861328125em;
  color: rgb(221, 221, 221);
}
.icon-contract .path3:before {
  content: "\e90e";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-contract .path4:before {
  content: "\e90f";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-contract .path5:before {
  content: "\e910";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-contract .path6:before {
  content: "\e911";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-contract .path7:before {
  content: "\e912";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-contract .path8:before {
  content: "\e913";
  margin-left: -0.861328125em;
  color: rgb(39, 41, 43);
}
.icon-duvida:before {
  content: "\e914";
}
.icon-finance_x .path1:before {
  content: "\e915";
  color: rgb(221, 221, 221);
}
.icon-finance_x .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-finance_x .path3:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-finance:before {
  content: "\e918";
}
.icon-foto:before {
  content: "\e919";
}
.icon-grup:before {
  content: "\e91a";
}
.icon-home:before {
  content: "\e91b";
}
.icon-inicial:before {
  content: "\e91c";
}
.icon-logoff:before {
  content: "\e91d";
  color: #ea3a45;
}
.icon-manut:before {
  content: "\e91e";
}
.icon-mensagem_x:before {
  content: "\e91f";
}
.icon-movim:before {
  content: "\e920";
}
.icon-os_x .path1:before {
  content: "\e921";
  color: rgb(221, 221, 221);
}
.icon-os_x .path2:before {
  content: "\e922";
  margin-left: -1.0615234375em;
  color: rgb(221, 221, 221);
}
.icon-os_x .path3:before {
  content: "\e923";
  margin-left: -1.0615234375em;
  color: rgb(221, 221, 221);
}
.icon-os_x .path4:before {
  content: "\e924";
  margin-left: -1.0615234375em;
  color: rgb(221, 221, 221);
}
.icon-os_x .path5:before {
  content: "\e925";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-os_x .path6:before {
  content: "\e926";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-os_x .path7:before {
  content: "\e927";
  margin-left: -1.0615234375em;
  color: rgb(0, 0, 0);
}
.icon-os-1:before {
  content: "\e928";
}
.icon-os:before {
  content: "\e929";
}
.icon-PDF .path1:before {
  content: "\e92a";
  color: rgb(191, 8, 17);
}
.icon-PDF .path2:before {
  content: "\e92b";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-PDF .path3:before {
  content: "\e92c";
  margin-left: -1.099609375em;
  color: rgb(226, 228, 231);
}
.icon-PDF .path4:before {
  content: "\e92d";
  margin-left: -1.099609375em;
  color: rgb(191, 8, 17);
}
.icon-PDF .path5:before {
  content: "\e92e";
  margin-left: -1.099609375em;
  color: rgb(0, 0, 0);
}
.icon-PDF .path6:before {
  content: "\e92f";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-PDF .path7:before {
  content: "\e930";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-PDF .path8:before {
  content: "\e931";
  margin-left: -1.099609375em;
  color: rgb(255, 255, 255);
}
.icon-posto_x .path1:before {
  content: "\e932";
  color: rgb(221, 221, 221);
}
.icon-posto_x .path2:before {
  content: "\e933";
  margin-left: -1.283203125em;
  color: rgb(33, 25, 21);
}
.icon-posto_x .path3:before {
  content: "\e934";
  margin-left: -1.283203125em;
  color: rgb(33, 25, 21);
}
.icon-posto:before {
  content: "\e935";
}
.icon-relt-1:before {
  content: "\e936";
}
.icon-relt:before {
  content: "\e937";
}
.icon-search:before {
  content: "\e938";
}
.icon-seta_01:before {
  content: "\e939";
}
.icon-seta_02:before {
  content: "\e93a";
}
.icon-sorrisoAlegre:before {
  content: "\e93b";
}
.icon-sorrisoTriste:before {
  content: "\e93c";
}
.icon-x:before {
  content: "\e93d";
}
.icon-menu:before {
  content: "\e962";
}
