.bg-black {
  background-color: #000;
  color: #fff;
}
.bloco-checklist > div {
  border-color: $cinza4;
  border-style: solid;
  border-width: 1px 0px 0px 1px;
}

.item-checklist > div {
  display: flex;
  align-items: center;
  height: 70px;
}

.listaOpcoes.erro {
  border: solid 3px theme-color-level(danger, 0);

  outline-offset: -2px;
}
.item-checklist .opcoes {
  flex: 1 1 auto;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  line-height: 70px;
  border-right: 1px solid $cinza4;
}

.item-checklist .check-all.opcoes {
  line-height: 18px;
  min-height: 70px;
  padding-top: 15px;
}
.item-checklist .opcoes:first-child {
  border-left: 1px solid #dddddd;
}
.item-checklist .opcoes span {
  font-weight: normal;
  display: block;
}

.item-checklist .opcoes.select {
  background-color: theme-color-level(primary, 0);
  border-color: theme-color-level(primary, 0);
}

.item-checklist .opcoes:last-child.select {
  background-color: $cinza4;
  border-color: $cinza4;
}

.bloco-checklist .item-checklist:last-child {
  border-bottom: 1px solid $cinza4;
}
.header-checklist div {
  border-width: 0px;
}

@media (max-width: 767px) {
  // .item-checklist > div {

  // }
  .item-checklist .opcoes {
    border-right: 1px solid #ddd;
  }
  .border-md {
    border-color: #dddddd;
    border-style: solid;
    border-width: 1px;
    border-left: 0px;
    border-top: 0px;
  }
}
