/* ---- CROP---- */

.h5Green {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #aaaaaa;
}

.listService {
  width: 642px;
  height: 75px;
  border-radius: 4px;
  border-top: 1px solid #bbbbbb;
  border-right: 1px solid #bbbbbb;
  border-left: 1px solid #bbbbbb;
  padding: 1em;
}

.listService:last-child {
  border-bottom: 1px solid #bbbbbb;
}

ul {
  padding: 0;
  list-style-type: none;
}

/* MOBILE */

@media (max-width: 1024px) {
  .listService {
    width: 100%;
  }
}
