/* ---- AJUSTES ---- */

.icon-disabled {
  opacity: 0.3;
}

.p-msg {
  color: #aaaaaa;
  text-align: center;
  margin: 15px auto;
  max-width: 380px;
}

/* ---- CROP---- */

/* MOBILE */

@media (max-width: 1024px) {
}
