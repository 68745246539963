.taskBody {
  background-color: white;
  width: 625px;
}

.rowStyle {
  cursor: pointer;
  min-height: 70px;
  vertical-align: middle;
  border-top: 1px solid #d6d6d6;
}

p span {
  display: block;
  font-weight: bold;
}

.col {
  text-align: left;
  vertical-align: middle;
}
