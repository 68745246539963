.progressbar-backgroung {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #000;
  height: 38px;
}

.progressbar-progress {
  background-color: #54a2b0;
  height: 34px;
  padding-top: 1px;
  border: 1px solid #54a2b0;
  border-radius: 6px;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 1px;
}

.progressbar-info {
  color: gray;
  text-align: right;
  font-size: 11px;
  padding-bottom: 4px;
}

.progressbar-label {
  text-align: right;
  padding-top: 37px;
}
