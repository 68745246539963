.checkbox input[type='checkbox'] + i,
.radio input[type='radio'] + i {
  width: 22px;
  height: 22px;
  background: url('./img/green@2x.png') 0 0 no-repeat;
  background-size: 240px 24px;
  display: inline-block;
  position: relative;
  bottom: -4px;
  margin-right: 5px;
}

.checkbox:hover input[type='checkbox'] + .icon-checkbox {
  background-position: -24px 0;
}

.checkbox input[type='checkbox']:checked + .icon-checkbox {
  background-position: -48px 0;
}

.checkbox input[type='checkbox']:disabled + .icon-checkbox {
  background-position: -72px 0;
}

.checkbox input[type='checkbox']:disabled:checked + .icon-checkbox {
  background-position: -96px 0;
}

.mt-1 {
  font-family: 'Dosis', sans-serif;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}
