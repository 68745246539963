.redux-toastr .toastr.rrt-error,
.redux-toastr .toastr.rrt-success,
.redux-toastr .toastr.rrt-info {
  height: 50px !important;
  border-radius: 50px !important;
}
.redux-toastr .toastr.rrt-error {
  background: theme-color-level(danger, 0) !important;
  border: 1px solid theme-color-level(danger, 0) !important;
}

.redux-toastr .toastr.rrt-info {
  background: theme-color-level(info, 0) !important;
  border: 1px solid theme-color-level(info, 0) !important;
}

.redux-toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
}

.redux-toastr .toastr.rrt-success {
  background: theme-color-level(success, 0) !important;
  border: 1px solid theme-color-level(success, 0) !important;
}

.redux-toastr .toastr .rrt-middle-container .rrt-title {
  line-height: 25px;
  margin-bottom: 0px;
}
.redux-toastr .toastr .rrt-middle-container .rrt-text {
  line-height: 25px;
  margin-bottom: 0px;
}
.redux-toastr .rrt-middle-container div:only-child {
  height: 50px;
  line-height: 50px !important;
  font-weight: bold;
}

.redux-toastr .toastr .close-toastr {
  color: #fff !important;
  text-align: right;
  margin-right: 20px;
  opacity: 1 !important;
  font-size: 16px !important;
}

.redux-toastr .toastr {
  width: 500px !important;
  padding: 0 !important;
  left: calc(50% - 250px) !important;
  display: flex;
  align-items: center !important;
  opacity: 1 !important;
  box-shadow: 0px 70px 0px 0px rgba(0, 0, 0, 0) !important;
}

.redux-toastr .toastr .rrt-middle-container {
  width: 425px;
  margin-left: 60px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1em;
  text-align: left;
  padding: 10px 5px;
}

@media (max-width: 575px) {
  // .redux-toastr .top-center {
  //   width: 95% !important;
  //   padding: 0 !important;
  //   left: 3% !important;
  //   margin-left: 0 !important;
  // }
  .redux-toastr .toastr .rrt-middle-container {
    width: 100%;
    margin-left: 0px;
  }

  .redux-toastr .toastr {
    width: 100% !important;
    position: initial;
    padding: 10px !important;
  }
}

// @media (max-width: 360px) {
//   // .redux-toastr .top-center {
//   //   width: 95% !important;
//   //   padding: 0 !important;
//   //   left: 3% !important;
//   //   margin-left: 0 !important;
//   // }

//   .redux-toastr .toastr.rrt-error {
//     width: 100% !important;
//     margin-left: 80px !important;
//     left: -23% !important;
//     background: theme-color-level(danger, 0) !important;
//     border: 1px solid theme-color-level(danger, 0) !important;
//   }

//   .redux-toastr .toastr .rrt-middle-container {
//     width: 100%;
//     margin-left: 20px;
//     font-family: 'Roboto', sans-serif !important;
//     font-size: 1em;
//     text-align: left;
//     padding: 10px 5px;
//   }

//   .redux-toastr .toastr .close-toastr {
//     color: #fff !important;
//     text-align: left;
//     padding-right: 16px;
//     opacity: 1 !important;
//     font-size: 16px !important;
//   }

//   .redux-toastr .toastr.rrt-info {
//     width: 100% !important;
//     margin-left: 80px !important;
//     left: -23% !important;
//     background: theme-color-level(info, 1) !important;
//     border: 1px solid theme-color-level(info, 1) !important;
//   }
// }
