/* ---- INDEX ---- */
p.maxWidth {
  max-width: 290px;
}

.logoSize {
  width: 200px;
  height: 120px;
}

.index_logo {
  background: url(./img/logo.svg) no-repeat;
  background-size: contain;
  width: 350px;
  height: 60px;
  align-self: center;
  position: fixed;
}

.colBorder {
  max-width: 1%;
  padding: 0;
}

.index_border {
  border-right: 1px solid #ddd;
  height: 100%;
  position: fixed;
}

.index_colMid_01 {
  background: url(./img/index/relogio_full.jpg) no-repeat;
  background-size: cover;
  background-position-x: 30%;
  height: 980px;
  border-radius: 20px 0px 0px 20px;
}

.index_colRight_01 {
  align-items: flex-start;
  background-color: #27292b;
  padding: 20px 20px 20px 70px;
  color: #fff;
  border-left: 20px solid #2e3134;
}

.index_colRight_01 h1 {
  margin: 0 0 40px;
  color: #fff;
}

.index_colRight_01 h5 {
  color: #fff;
  font-family: inherit;
  font-size: 11px;
  font-weight: 400;
  text-transform: inherit;
}

.index_colRight_01 p {
  color: #fff;
}

.index_form {
  max-width: 320px;
  text-align: center;
}

.index_colRight_02 {
  background: url(./img/index/index_back.svg) no-repeat;
  background-size: cover;
  height: 100%;
  width: 36%;
  padding: 20px 70px;
  text-align: right;
  position: absolute;
  top: -1px;
  right: 0;
  z-index: -1;
}

.index_input {
  width: 300px;
}

.index_btLogin {
  margin: 0 0 auto;
  align-self: flex-end;
}

.index_btCadastro {
  margin: 20px 10px auto;
  align-self: flex-end;
}

/* MOBILE */

@media (max-width: 1024px) {
  /* ---- INDEX ---- */
  .index_logo {
    background: url(./img/logo.svg) no-repeat;
    height: 95px;
    width: 150px;
    margin: 30px auto;
    align-self: unset;
    position: unset;
    background-size: contain;
  }
  .index_border {
    border-right: none;
    max-width: 0;
    padding: 0;
    height: 0;
    position: unset;
  }
  .index_colMid_01 {
    background-size: cover;
    height: 150px !important;
    min-width: 100%;
    border-radius: 0;
    background-position-y: 40%;
  }
  .index_colRight_01 {
    align-items: center;
    border-left: none;
    border-top: 20px solid #2e3134;
    padding: 60px 31px;
    text-align: center;
  }
  .index_colRight_01 h1 {
    margin: 0 0 16px;
    max-width: 300px;
  }
  .index_colRight_01 h5 {
    max-width: 200px;
    letter-spacing: 2px;
  }
  .index_colRight_01 button {
    margin: 0 0 20px;
  }
  .index_btLogin {
    margin: 0 0 20px;
    align-self: center;
  }
  .index_btCadastro {
    margin: 60px 0 20px;
    align-self: center;
  }
  .index_colRight_02 {
    width: 35%;
  }
}
