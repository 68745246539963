/* ---- AJUSTES ---- */

.itemListaOS {
  font-family: 'Roboto', sans-serif;
  color: #000;
  height: 80px;
  position: relative;
  padding: 5px 20px;
}
.itemListaOS:before {
  content: '';
  position: absolute;
  left: 3%;
  bottom: 0;
  height: 1px;
  width: 94%;
  border-bottom: 1px solid $cinza4;
}

.itemListaOS:hover {
  background-color: $cinza2;
  color: $cinza1;
  border-radius: 20px;
}

.itemListaOS .tipo {
  font-size: $font-lg;
  font-weight: 700;
  letter-spacing: -1px;
}

.itemListaOS .item {
  font-size: $font-df;
}

.itemListaOS .data {
  font-size: $font-xs;
  text-transform: uppercase;
  font-weight: 600;
}

.itemListaOS.osSelecionada {
  background-color: $cinza2;
  color: $cinza1;
  border-radius: 20px;
}

.itemListaOS.osSelecionada .data {
  text-transform: uppercase;
}

/* ---- CROP---- */

/* MOBILE */

@media (max-width: 575px) {
  .itemListaOS .tipo {
    font-size: 16px;
  }
  .itemListaOS .item {
    font-size: 11px;
  }
  .itemListaOS .data {
    font-size: 8px;
  }
}
