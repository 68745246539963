/* ---- CARD POSTO AUTORIZADO---- */

/* 
h2 {
    margin: 0;
} */

p.legend {
  font-style: italic;
  font-weight: bold;
  color: #aaaaaa;
}

/* .cardDefault h3 {
    color: #AAA;
    margin: 21px 4px; 
}

.cardDefault a.normal {
    text-decoration: underline !important;
    color: #2B60E8 !important;
}  */

.fotosLoja {
  width: 100%;
  border-radius: 4px;
  height: 150px;
}

/* MOBILE */

@media (max-width: 1024px) {
  /* .btn {
        min-width: 0;
    } */
  /* .cardDefault h3 {
        margin: 40px auto;
    } */
}
