/* AJUSTES */

.tbody tr td:last-child {
  margin-bottom: 20px;
}

/* MOBILE */

.separator {
  width: 100%;
  height: 1px;
  background-color: #dddddd;
}

.docView .headerGrid {
  padding: 20px 10px 0px 10px;
}

.itemListagemOS {
  color: #000;
  padding: 1px 10px;
  font-size: 14px;
}

tr.itemListagemOS:last-child {
  border-bottom: 1px solid #dddddd;
  color: #000;
  padding: 1px 10px;
  font-size: 14px;
}

/* .itemListagemOS.fim_OS {
    border-bottom: 2px solid #000000;
} */

.table.tableDoc thead th {
  vertical-align: inherit;
  border-bottom: 0px solid transparent;
}

.table.tableDoc td,
.table.tableDoc th {
  padding: inherit;
  vertical-align: inherit;
  border-top: 0px solid transparent;
  display: inline-flex;
}

.numeroOs {
  font-size: 24px;
  font-weight: 500;
  display: inline-flex;
}

.destaqueFinanceiro {
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
}

.itemListagemOS .status {
  text-transform: uppercase;
}

.atendido a {
  text-align: left;
}

/* Navegação */

.react-bootstrap-table-page-btns-ul .page-item {
  font-family: 'Roboto', sans-serif;
  margin: 0px 3px;
}

.page-item .page-link {
  color: #000;
  background-color: transparent;
  border-color: #ddd;
  text-decoration: none;
  padding: 5px 9px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
}

.page-item.active .page-link {
  color: #ddd;
  background-color: transparent;
  border-color: #ddd;
  text-decoration: none;
  font-weight: 400;
  cursor: none;
}

.react-bs-container-body.docView table tbody tr {
  border-bottom: none;
}

.docView tr.itemListagemOS:last-child {
  border-bottom: 3px solid #171515;
}

.docView .react-bs-container-body table tbody tr {
  padding: 1px 10px;
}

.docView .react-bs-table table {
  margin-bottom: 1rem;
}
.react-bs-container-body.docView table tbody tr.trocaMain {
  border-top: 1px solid #ddd;
}

.react-bs-container-body.docView table tbody tr.trocaLast {
  border-bottom: 1px solid #ddd;
}

@media (max-width: 1024px) {
}

@media (max-width: 575px) {
  .tableDoc td,
  .tableDoc th {
    display: table-cell;
  }
}
