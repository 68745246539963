/* ---- AJUSTES ---- */

.h1-valor {
  color: #aaaaaa;
}

.p-msg {
  color: #aaaaaa;
  text-align: center;
}

/* ---- CROP---- */

/* MOBILE */

@media (max-width: 1024px) {
}
