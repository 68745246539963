/* ---- ITENS DEFAULT ---- */

html {
  // overflow-y: scroll;
}

body {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h6 {
  font-family: 'Roboto Slab', serif;
  font-weight: normal;
}

h1 {
  font-size: $font-lg;
}

h2 {
  font-size: $font-md;
}
h2 > img {
  width: 20px;
  margin-right: 10px;
  float: left;
}
.cardSeculus{
  min-width: 1000px;
}
h3 {
  font-size: $font-sm;
}

.titulo-com-imagem {
  line-height: 35px;
}

p {
  font-size: $font-df;
  margin-bottom: 0.5rem;
}
p.destaque {
  font-size: $font-sm;
  font-weight: bold;
}

a {
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  font-size: $font-df;
  text-align: center;
  letter-spacing: 0.1em;
  text-decoration: underline;
  color: #4c4c4c;
  cursor: pointer;
}

a:hover {
  color: $cinza1;
}

label.txtCinza {
  margin-bottom: 0rem;
  color: $cinza1;
}

.txtGreen,
.verde {
  color: #85cd63 !important;
}

.txt-primary {
  color: theme-color-level(primary, 0) !important;
}

.border-sm-left {
  border-left: 1px solid $cinza4;
}

.txtBlue {
  color: #2b60e8 !important;
}

.txtDarkGray {
  color: #4c4c4c !important;
}

.txtGray {
  color: #dddddd !important;
}

.txtRed {
  color: #ea3a45 !important;
}

.txtUppercase {
  text-transform: uppercase;
}

.txtBold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

/* BUTTONS */

.btn {
  font-weight: 600;
  font-size: $font-btn;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.1em;
  min-width: 100px;
  // text-transform: uppercase;
  text-decoration: none;
  border-radius: $border-radius-btn;
}

.btn-link {
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  font-size: $font-df;
  text-align: center;
  letter-spacing: 0.1em;
  text-decoration: underline;
  color: #4c4c4c;
  text-transform: initial;
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: none;
}

.btn-lg {
  padding: 12px 40px;
  font-size: $font-df;
}

.btn-float-modal {
  position: absolute;
  bottom: -45px;
  left: 0;
}
.modalOrcamento .btn-float-modal {
  bottom: -35px;
}

.btn-float-alert {
  position: absolute;
  bottom: -15px;
  left: 0;
}

#termo {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1000;
  right: -100%;
  transition: all 0.3s ease-in-out;
  height: 100%;
  overflow-y: auto;
}

#termo.show {
  right: 0;
  transition: all 0.5s ease-in-out;
}

#termo .aceite {
  opacity: 0;
  transition: all 1s ease-in-out;
}
#termo.show .aceite {
  display: block;
  opacity: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 2s ease-in-out;
  background-color: white;
}

.btn.btn-link.active {
  color: #b38700;
}

.conteudo-termo {
  overflow-y: auto;
}
.icone-lupa {
  background: url(./img/icon_search_lupa.svg) no-repeat center center;
  height: 35px;
  width: 25px;
}
.icone-email {
  background: url(./img/icon_email.svg) no-repeat center center;
  height: 35px;
  width: 35px;
}

.thumb-custom {
  object-fit: cover;
  border-radius: 6px;
}
.btn-primary {
  color: $txt-btn-primary;
  border: none;
  border: 1px solid $border-color-btn-primary;
}

.btn-secondary {
  border: 1px solid theme-color-level(secondary, 0);
}

.btn-light {
  border: 1px solid $cinza4;
}

// .btn-secondary.white {
//   background: #ff0000 !important;
//   color: #000 !important;
//   border: 1px solid #ddd;
// }

.btn-outline-secondary {
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  opacity: 1;
  color: #bbb;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

.btn-no-padding {
  min-width: 100px;
  white-space: normal;
  padding: 0px 7px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #eeeeee;
  opacity: 1;
  border-color: #eeeeee;
  color: #bbb;
}

.btn-sm {
  height: 20px;
  line-height: 1.5;
}
.back-index {
  background: url($bg-back-index) no-repeat $bg-align;
  background-size: $bg-size;
}

.back-entrar {
  background: url($bg-login) no-repeat $bg-align;
  background-size: $bg-size;
  color: $font-color-login;
}
.back-entrar .link a {
  color: $font-color-login;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000;
  opacity: 0.5;
}

.backdrop.hide {
  opacity: 0;
}

/* CAROUSEL */

.slick-list {
  margin: 0 30px;
}

.slick-prev,
.slick-next {
  width: 25px;
  height: 100%;
  color: #000;
}

.slick-prev {
  left: 0px;
}

.slick-next {
  right: 0px;
}

.slick-prev:before,
.slick-next:before {
  font-size: $font-lg;
  color: #000;
}

.avatarContainer img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.mini-link {
  font-size: 11px;
  color: #000000;
}
/* ICONS */

.icon_arrow {
  background: url(./img/icon_arrow.svg) no-repeat;
  width: 7px;
  height: 11px;
}

.icon_error {
  width: 19px;
  height: 15px;
  margin: 11px 0 0 15px;
}

.icon_close {
  width: 14px;
  height: 14px;
  margin: 12px 20px 0 0;
}

.icon_alert {
  width: 30px;
}

.icon_archive {
  width: 20px;
  margin-right: 10px;
}

/* FORMS */

label {
  font-size: $font-xs;
}

.form-group {
  text-align: left;
  margin-bottom: 10px;
}

.form-control {
  border: 1px solid $cinza1;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: $font-xs;
  height: 35px;
}

.form-control::placeholder {
  font-size: $font-xs;
  color: $cinza1;
}

.form-control:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  border: 1px solid #aaa;
}
textarea.form-control {
  height: 100px;
}

.Select .Select-input {
  height: 33px;
}
.Select .Select-control {
  border-color: $cinza1;
  height: 35px;
  border-radius: 6px;
}

.Select .Select-control .Select-arrow {
  border-color: #000 transparent transparent;
  border-radius: 4px;
}

.Select-value-label {
  font-size: $font-xs;
}

.borderForm .select-blue span.Select-value-label {
  color: #2b60e8 !important;
  font-weight: bold;
  font-size: $font-xs;
}

.Select-menu-outer {
  z-index: 99999;
  font-size: $font-xs;
}

.Select-menu-outer .Select-option {
  font-size: $font-xs;
}

.Select-menu-outer .Select-option.is-focused {
  background-color: theme-color-level(primary, -5);
}

input.error {
  background: #ffffff;
  border: 2px solid #ea3a45;
  box-sizing: border-box;
  border-radius: 4px;
}

.Select.error {
  background: #ffffff;
  border: 2px solid #ea3a45;
  box-sizing: border-box;
  border-radius: 4px;
}

textarea {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.1em;
  resize: none;
}
textarea:focus,
input:focus {
  outline: none;
}

.custom-select {
  background: #fff url('./img/icon_selected.svg') no-repeat right 0.75rem center;
  background-size: 8px;
}

.checkbox,
.radio {
  font-family: 'Dosis', sans-serif;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: $font-df;
  line-height: 20px;
}

.checkbox:hover,
.radio:hover {
  cursor: pointer;
}

.indent {
  padding-left: 30px;
}

.indent .icon-checkbox,
.indent .icon-radio {
  margin-left: -30px;
}

.checkbox input[type='checkbox'],
.radio input[type='radio'] {
  display: none;
}

/* badge */
.badge {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: -12px;
  padding: 6px 4px;
  font-size: $font-df;
  min-width: 25px;
}
.nav-item .badge {
  right: 0px;
  top: -10px;
}

.badge-danger {
  color: $branco;
}

/* Farol */

.farol {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin: auto;
}

.farol-criacao {
  background-color: $cinza1;
  border: 1px solid $cinza1;
}

.farol-branco {
  background-color: #ffffff;
  border: 1px solid #000000;
}

.farol-vermelho {
  background-color: #ea3a45;
  border: 1px solid #ea3a45;
}

.farol-amarelo {
  background-color: #ffe34e;
  border: 1px solid #ffe34e;
}

.farol-verde {
  background-color: #85cd63;
  border: 1px solid #85cd63;
}

.farol-azul {
  background-color: #2b60e8;
  border: 1px solid #2b60e8;
}

/* Checkbox */

.checkbox input[type='checkbox'] + i,
.radio input[type='radio'] + i {
  width: 22px;
  height: 22px;
  background: url('./img/green@2x.png') 0 0 no-repeat;
  background-size: 240px 24px;
  display: inline-block;
  position: relative;
  bottom: -4px;
  margin-right: 5px;
}

.checkbox:hover input[type='checkbox'] + .icon-checkbox {
  background-position: -24px 0;
}

.checkbox input[type='checkbox']:checked + .icon-checkbox {
  background-position: -48px 0;
}

.checkbox input[type='checkbox']:disabled + .icon-checkbox {
  background-position: -72px 0;
}

.checkbox input[type='checkbox']:disabled:checked + .icon-checkbox {
  background-position: -96px 0;
}

/* Radio */

.radio:hover input[type='radio'] + .icon-radio {
  background-position: -144px 0;
}

.radio input[type='radio'] + .icon-radio {
  background-position: -120px 0;
}

.radio input[type='radio']:checked + .icon-radio {
  background-position: -168px 0;
}

.radio input[type='radio']:disabled + .icon-radio {
  background-position: -192px 0;
}

.radio input[type='radio']:disabled:checked + .icon-radio {
  background-position: -216px 0;
}

/* TOAST */

.toast {
  position: fixed;
  z-index: 100;
  top: 5px;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  min-height: 40px;
  max-width: 625px;
  margin-left: -350px;
  border-radius: 50%;
  left: 50%;
  padding: 0.3rem 1.75rem;
}

.toast p {
  color: #fff;
  font-weight: 400;
  margin: auto;
  padding: 10px;
}

/* CARDS */

.content-wrap > .flex-column:last-child {
  border-left: 1px solid $border-color;
}
.content-wrap > .flex-column:only-child {
  border-left: none;
}

.content-wrap {
  background: url($bg-content) no-repeat $bg-align-content;
  background-size: $bg-size;
  min-height: calc(100vh - 100px);
}

.cardDefault {
  background-color: #ffffffe6;
  border: none;
  // border-left: 1px solid #ddd;
  // border-right: 1px solid #ddd;
  margin: 0px 5px 10px 5px;
  padding: 15px 20px 20px;
  width: 625px;
  border-radius: 0px;
}

.cardDefault.w1 {
  width: 500px;
}

.cardDefault.w2 {
  width: 751px;
}

.cardDefault .btn {
  min-width: 108px;
}
.cardDefault .topo-card-collapse {
  border-bottom: 1px solid $cinza4;
}
.cardDefault .topo-card-collapse h2 {
  border-bottom: none;
  text-align: left;
  padding-right: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.cardDefault .topo-card-collapse::after {
  margin-left: 10px;
  height: 35px;
  width: 20px;
  content: '';
  margin-right: 10px;
}
.cardDefault .topo-card-collapse::after {
  background: url(./img/seta-collapse.svg) no-repeat center center;
}
.cardDefault .topo-card-collapse[aria-expanded='true']::after {
  background: url(./img/line-collapse.svg) no-repeat center center;
}

.conteudoCard.mh-500{
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  height: 500px;
}

.btn.btn-sm {
  min-width: 80px;
  padding: 15px 10px;
  line-height: 1px;
}

.btn.btn-sm > [class^='icon-'],
[class*=' icon-'] {
  line-height: 1px;
}
.cardDefault .boxButon {
  margin-left: auto;
}
.cardDefault .boxButon button {
  margin-right: 5px;
  min-width: 100px;
  height: 30px;
}

.cardDefault .boxButon button:last-child {
  margin-right: 0px;
}

.cardDefault.a1 .conteudoCard {
  height: 110px;
}

.cardDefault.a2 .conteudoCard {
  height: 320px;
}

.cardDefault .conteudoCard {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.topoCard {
  margin-bottom: 10px;
}

.cardDefault h2 {
  font-weight: bold;
  padding-right: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid theme-color-level(primary, 0);
}
.border-botom-principal {
  border-bottom: 2px solid theme-color-level(primary, 0);
}

.cardDefault .destaqueTxtCard {
  font-size: $font-lg;
  font-weight: bold;
  color: #4c4c4c;
  margin-bottom: 0px;
}
.btn.btn-sm.btn-p-relative {
  position: relative;
  padding: 15px 20px 15px 35px;
}
.icone-google {
  background: url(./img/icon_google.svg) no-repeat center center;
  height: 18px;
  width: 18px;
  position: absolute;
  left: 6px;
  top: 6px;
  background-size: contain;
}

.box-descricao-legenda {
  position: fixed;
  width: 20%;
  left: 0;
  padding: 20px 0 0 55px;
}
.cardDefault .subDestaqueTxtCard {
  font-size: $font-df;
  text-transform: uppercase;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0px;
}

.cardDefault .conteudoCard .form-group {
  width: 100%;
}

/* .cardDefault .rodapeCard {
  padding-bottom: 10px;
} */

.cardDefault.a1 > .conteudoCard {
  height: auto;
}

.cardDefault.a2 > .conteudoCard {
  height: auto;
}

.cardDefault.cardFull {
  width: 100%;
  margin: 0px 0px 10px 0px;
  border-top: none;
}

.cardDefault.miniCard {
  width: 500px;
  min-height: 317px;
}

.cardDefault.emptyOS {
  width: 751px;
}

.miniLabel {
  font-family: 'Roboto Slab', serif;
  color: #aaa;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 2px;
}
.mh-100vh {
  min-height: 100vh;
}
/* CardTab */

// .cardDefault.cardTab {
//   margin: 0px 5px 0px 5px;
//   padding: 10px 5px;
// }

.cardDefault.cardTab ul.nav li {
  // width: 22%;
  // width: 200px;
  margin-bottom: -1px;
  position: relative;
  margin-right: 40px;
}

// .cardDefault.cardTab ul.nav li:last-child {
//   width: 12%;
//   padding: 0.5rem 1rem 0.5rem 0px;
// }

.nomeCargoColaborador {
  font-size: 11px;
}
.cardDefault.cardTab ul.nav {
  border-bottom: 1px solid #dfe1e2;
  margin-bottom: 30px;
}

.cardDefault.cardTab ul.nav li div.aba {
  font-family: 'Roboto Slab', serif;
  font-size: $font-md;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  width: 100%;
  padding: 0.5rem 5px;
  cursor: pointer;
  text-align: left;
}

.cardDefault.cardTab .tab-content {
  padding: 15px;
}

.cardDefault.cardTab ul li div.aba.active {
  border-bottom: 2px solid theme-color-level(primary, 0);
}

.border-bottom {
  border-bottom: 1px solid #ddd !important;
}

.cardDefault.cardTab .nOs {
  padding: 0.5rem 1rem;
  text-align: right;
  font-weight: bold;
}

.icon-lg {
  font-size: 50px;
}

.containerImg {
  height: 120px;
  width: 120px;
}

.containerImg img {
  object-fit: contain;
}

.icon-md {
  font-size: 35px;
}

.icon-search.boxIcon,
.icon-x.boxIcon {
  margin: 0px 8px;
  line-height: 30px;
  color: #000;
  align-self: end;
  cursor: pointer;
}

.boxInputSearch {
  height: 30px;
  border-radius: 20px;
  margin-right: 10px;
  width: 200px;
}

.obsTxt {
  font-style: italic;
  color: #6c6a6a;
}
.underline .btn-link {
  text-decoration: underline !important;
}
.underline .btn-link:hover {
  text-decoration: underline !important;
}
/* RODAPE */

.rodapeFixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.rodape {
  font-size: $font-xs;
  text-align: right;
  align-self: flex-end;
  margin: 0 20px 10px;
  text-transform: inherit;
}
.rodape a {
  font-size: $font-xs;
  text-decoration: none;
}
.rodape a:hover {
  text-decoration: underline;
}

.iconRodape {
  width: 18px;
  margin: 5px 10px 7px;
}

.legenda {
  text-align: left;
  margin: 0 40px 20px;
  text-transform: inherit;
}

.cardDefault .legenda {
  margin: 40px 0 20px 0;
}

/* LISTAGEM GRID */

.table thead th {
  padding: 2px 10px;
}

.table tbody {
  font-family: 'Roboto', sans-serif;
}

.table tbody .colunaDestaque {
  font-size: $font-lg;
  font-weight: 700;
  letter-spacing: -1px;
}

.table tbody tr td {
  border-top: 0px solid transparent;
}

.mt-lbl {
  margin-top: 19px;
}

.tableDetailDoc tbody > tr > td {
  padding: 0.75rem 0.3rem;
}

.icon-closesearch {
  background: url(./img/bt_close.svg) no-repeat ;
  background-position-y: center;
}
.icon-closesearch .path1:before {
  content: "\f067";
  color: white;
}
// COLLAPSES
.collapses .etapa .topo-etapa {
  height: 80px;
  display: flex;
  align-items: center;
}

.bloco-form {
  border: 1px solid $cinza4;
  border-radius: 4px;
  padding: 0.5rem 1rem !important;
}

.bloco-form p {
  margin-bottom: 0rem;
}

.collapses .etapa .conteudo-etapa {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.collapses .etapa .topo-etapa.accordion-toggle {
  border-top: 1px solid $cinza1;
}
.collapses .etapa:last-child .topo-etapa.accordion-toggle {
  border-bottom: 1px solid $cinza1;
}

.collapses
  .etapa:last-child
  .topo-etapa.accordion-toggle[aria-expanded='true'] {
  border-bottom: none;
}

.collapses .etapa .topo-etapa.collapsed {
  border-top: 1px solid $cinza1;
  border-bottom: none;
}

.collapses .etapa .topo-etapa .data-hora {
  margin-left: auto !important;
  display: inline-grid;
  text-align: right;
}
.collapses .etapa .topo-etapa .autor {
  display: flex;
  flex-direction: row !important;
  padding-left: 1rem !important;
}
.collapses .etapa .topo-etapa.conteudo {
  cursor: pointer;
}
.collapses .etapa .topo-etapa.conteudo[aria-expanded='true'] {
  color: $cinza1;
}
.collapses .etapa .topo-etapa::after {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  content: '';
}
.collapses .etapa .topo-etapa.conteudo::after {
  background: url(./img/seta-collapse.svg) no-repeat center center;
}
.collapses .etapa .topo-etapa.conteudo[aria-expanded='true']::after {
  background: url(./img/line-collapse.svg) no-repeat center center;
}

/* ANIMATIONS */

.slide-left {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
    both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

@keyframes slide-left {
  0% {
    transform: translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-up {
  -webkit-animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
    both;
  animation: slide-up 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

@keyframes slide-up {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
    both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

@keyframes slide-right {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-down {
  -webkit-animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s
    both;
  animation: slide-down 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

@keyframes slide-down {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* MODAL */

.modal {
  text-align: center;
  padding: 0 !important;
  z-index: 2000 !important;
}
.modal-content {
  padding: 15px;
}
// .modal:before {
//   content: '';
//   display: inline-block;
//   // height: 100%;
//   vertical-align: middle;
//   margin-right: -4px;
// }

// .modal-open .modal {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
//   .modal-dialog {
//     flex-grow: 1;
//   }
// }
// .modal-checklist .modal-open .modal {
//   align-items: initial !important;
// }

.modal-dialog.modal-sm {
  max-width: 360px;
  // display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-dialog.modal-lg {
  width: 790px;
  // display: inline-block;
  text-align: left;
  vertical-align: middle;
  min-height: 700px;
}
.modal-dialog.modal-lg .modal-content {

  min-height:300px;
}
// .modal-body {
//   // padding: 30px;
// }
.corpo-modal {
  padding-bottom: 30px;
}

.modal-body .container {
  padding: 0;
}

.modal-body h3 {
  color: #aaaaaa;
}

.border-right {
  border-right: 1px solid #ddd !important;
}

.modalAlert h3 {
  margin-bottom: 0rem;
  line-height: 21px;
}

.modalAlert .modal-body h3.subH3 {
  margin-bottom: 0.5rem;
}

.modalAlert .modal-body {
  padding-bottom: 5px;
}

.modalAlert .modal-footer {
  padding-top: 5px;
  border-top: 0px solid transparent;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.no-border {
  border: none;
}

/* CLIENT */

.logoHzt {
  width: 110px;
  height: 20px;
}

/* LIST */

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item {
  padding: 1.3rem 0.25rem;
  cursor: pointer;
}

.list-group-flush a {
  text-decoration: none;
}

/* Mention */

ul.mention {
  position: absolute;
  width: 300px;
  border-radius: 6px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px;
  list-style: none;
  padding-left: 0px;
}

ul.mention li.content {
  padding: 10px 20px;
}

ul.mention li.content .person {
  display: inline-flex;
  text-decoration: none;
  font-style: normal;
  color: #252525;
  font-weight: normal;
}

ul.mention li.content.active,
ul.mention li.content:focus,
ul.mention li.content:hover {
  background-color: #f9f9f9;
  /* border-top: 1px solid #eef5f9; */
}

.avatar-img {
  border-radius: 100px;
  width: 50px;
  position: relative;
  top: -3px;
}

.bg-img-avatar {
  background: url($bg-img-avatar) no-repeat;
  background-size: contain;
}

.bg-img-add-avatar {
  background: url($bg-img-add-avatar) no-repeat;
  background-size: contain;
}

.w-80 {
  width: 80px;
}
.h-80 {
  height: 80px;
}

ul.mention li.content .dadosUser {
  text-align: left;
  padding: 0px 10px;
}

ul.mention li.content .nomeMsg {
  font-weight: bold;
}

ul.mention li.content .dataMsg {
  min-width: 60px;
}

ul.mention li.content .setor {
  font-size: $font-df;
}

ul.mention li.content .localidade {
  font-size: $font-df;
}

ul.mention li.content .cargo {
  font-size: $font-xs;
}

.bold {
  font-weight: bold;
}

.formView .form-group p {
  font-weight: bold;
}

.hrBold {
  border: 2px solid #000000;
}

.small .page-link {
  font-size: 80%;
}

.header .bt-voltar {
  margin-left: 15px;
  height: 100px;
  padding: 0px 15px;
  background-color: theme-color-level(primary, 0);
}
.header .bt-voltar [class^='icon-'],
[class*=' icon-'] {
  color: $color-icon-back;
}

.sep-large {
  margin-top: 2rem;
  border-top: 1px solid rgb(221, 221, 221);
}

/* MOBILE */

@media (max-width: 1024px) {
  /* ---- ITENS DEFAULT ---- */
  // h1 {
  //   font-size: 30px;
  // }
  // h2 {
  //   font-size: 24px;
  // }
  // h3 {
  //   font-size: 18px;
  // }
  // h4 {
  //   font-size: 14px;
  // }
  // h5 {
  //   font-size: 8px;
  //   letter-spacing: 3px;
  // }
  // h6 {
  //   font-size: 8px;
  //   letter-spacing: 3px;
  // }
  // p {
  //   font-size: 13px;
  // }
  /* .btn-secondary {
        height: 30px;
    } */
  /* CARDS */
  .cardDefault {
    width: calc(100% - 2px);
  }
  .cardDefault.w1 {
    width: 100%;
  }
  .cardDefault.w2 {
    width: 100%;
  }
  .cardDefault.a1 {
    height: auto;
  }
  .cardDefault.a2 {
    height: auto;
  }
  .cardDefault.a1 .conteudoCard {
    height: auto;
  }
  .cardDefault.a2 .conteudoCard {
    height: auto;
  }
  .cardDefault.cardTab ul li a {
    font-size: 12px;
    line-height: 19px;
  }
  .cardDefault.miniCard {
    width: 100%;
  }
  .cardDefault.cardTab ul.nav li {
    width: 25%;
  }
  .cardDefault.cardFull {
    margin: 0px 5px 10px 5px;
  }
  .cardDefault.emptyOS {
    width: 100%;
  }
  /* RODAPE */
  .rodapeFixed {
    position: inherit;
  }
  .rodape {
    text-align: center;
    align-self: center;
    margin: 10px 10px 0;
  }
  .toast {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 5px;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    margin-left: auto;
    min-height: 40px;
    /* max-width: 100%; */
    left: 0;
    padding: 0.3rem 0.75rem;
  }
  .legenda {
    text-align: center;
    margin: 20px 0 10px 0;
  }
  .header .bt-voltar {
    height: 100px;
    // margin-left: 20px;
  }
}

.react-datepicker-wrapper > div {
  display: block;
}
.react-datepicker-wrapper {
  width: 100%;
}


@media (max-width: 767px) {
  .modal-dialog.modal-lg {
    width: 100%;
  }
  .farol {
    margin-top: 13px;
  }
  .ratingItem {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 575px) {
  .cardDefault {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
  }
  .cardDefault.w1 {
    width: 100%;
  }
  .cardDefault.w2 {
    width: 100%;
  }
  .cardDefault.a1 {
    height: auto;
  }
  .cardDefault.a2 {
    height: auto;
  }
  .cardDefault.a1 .conteudoCard {
    height: auto;
  }
  .cardDefault.a2 .conteudoCard {
    height: auto;
  }
  .cardDefault .topoCard {
    text-align: center;
    height: 60px;
  }
  .cardDefault .boxButon {
    margin-left: 0;
  }
  .cardDefault .btn {
    height: auto;
    min-height: 30px;
    white-space: normal;
    line-height: 12px;
  }
  .cardDefault .btn.btn-sm {
    height: auto;
    min-height: 30px;
    white-space: normal;
    line-height: 12px;
  }
  /* .cardDefault.cardTab .tab-content {
        padding: 15px 0px;
    } */
  .cardDefault.cardTab .tab-content .container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .message .fotoPerfil {
    max-width: 55px;
  }
  .mt-lbl {
    margin-top: 25px;
  }
  .modal-content .conteudo {
    margin-bottom: 10px;
  }
  .cardDefault .conteudoCard {
    margin-top: 10px;
  }
  .cardDefault h3 {
    font-size: 17px;
  }
  .cardDefault.cardTab ul li a {
    font-size: 8px;
    line-height: 19px;
  }
  .border-right {
    border-right: 0px !important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .cardDefault .conteudoCard .subDestaqueTxtCard {
    font-size: 14px;
  }
  .cardDefault .conteudoCard .destaqueTxtCard {
    font-size: 24px;
  }
  .icon-lg {
    font-size: 60px;
  }
  .farol {
    height: 13px;
    width: 13px;
  }
  .box-descricao-legenda {
    position: relative;
    width: 100%;
    left: 0;
    padding: 15px;
  }
  .header .navTopo {
    padding-left: 5px;
  }
  .header .bt-voltar {
    height: 100px;
    margin-left: -24px;
  }

  .titulo-topo .titulo {
    font-size: 18px;
  }

  .titulo-topo .sub-titulo {
    font-size: 13px;
  }

  .total-text {
    text-align: right;
    margin-bottom: 3px;
  }
  .avatarContainer img {
    width: 50px;
    height: 50px;
  }
  .nomeColaborador {
    font-size: 10px;
    line-height: 10px;
  }
}

.width-full {
  width: 100%;
}

.react-bs-container-body.tablePecas tbody tr {
  font-size: 12px !important;
}

.react-bs-table table td {
  white-space: normal;
}

.btn {
  cursor: pointer;
}

.wb {
  word-break: break-all;
}

.action .chartjs-render-monitor {
  cursor: pointer;
}

.react-confirm-alert
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 6px;
}

.cardDefault.mh-0 {
  min-height: 0px;
}

.card-b-none .cardDefault {
  border-right: none;
  border-left: none;
}

.sidebar i.icon-relogio-menu {
  font-size: 23px;
}

.w-20 {
  width: 20%;
}
.titulo-collapse {
  width: 40%;
}
.titulo-collapse h1 {
  font-weight: bold;
}

/* .cardDefault{
  margin: 0 0 10px 0px;
  background: #fff;
} */

/* 
.flex-column{
  padding-right: 5px;
} */

.action {
  cursor: pointer;
}
.form-group label {
  margin-bottom: 0.1rem;
}
#root .form-group label.is-invalid {
  color: #ea3a45;
}

.form-control.is-invalid {
  border: 2px solid #ea3a45;
}

.error {
  border: 2px solid #ea3a45;
}

.is-invalid {
  color: #ea3a45;
}

.wizard-card .nav-pills > li.active .icone-circle {
  z-index: 200;
}

.tooltip {
  font-family: 'Roboto', sans-serif;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  padding-left: 10px;
  padding-right: 10px;
}

.invalid-tip .tooltip-inner {
  background-color: #ea3a45;
}

.invalid-tip .arrow::before {
  border-top-color: #ea3a45;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: #ea3a45;
}

#sectionCadastro h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

.conteudoCard h5 {
  font-size: 11px;
}

.conteudoCard .title {
  padding-left: 0;
  padding-top: 0;
  font-weight: 600;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #dddddd;
}

.bodyWhite {
  background-color: #fff !important;
}

.modal-content {
  border: none;
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.modalLoading .modal-content {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: none;
}

/* MODAL CROP IMAGE */

.customModal .modal-body {
  padding: 16px 16px 0 16px;
}

.customModal .modal-footer {
  justify-content: center;
  border: none;
  padding: 0;
}

.react-confirm-alert-body > h1 {
  color: #025aae !important;
}

.react-confirm-alert-body {
  text-align: center !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
  display: flow-root !important;
  width: 500px;
  padding: 40px;
  background: #fff;
  -webkit-box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.react-confirm-alert-button-group > button {
  background: #025aae !important;
  color: #fff !important;
  width: 120px;
  border-radius: 6px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px 10%;
  cursor: pointer;
  font-size: 14px;
  left: 0;
}

.react-confirm-alert-button-group {
  text-align: center !important;
  align-content: center !important;
  display: flow-root !important;
}

.cancel-ui {
  text-align: center !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
  display: flow-root !important;
  width: 500px;
  padding: 30px 20px 20px;
  background: #fff;
  -webkit-box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.confirm-ui {
  position: relative;
  text-align: center !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
  display: flow-root !important;
  width: 500px;
  padding: 30px 20px 45px 20px;
  background: #fff;
  -webkit-box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 7px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.mh-290 {
  min-height: 290px;
}
.confirm-ui.success > h3 {
  color: theme-color-level(success, 0) !important;
  font-weight: 600;
}

.confirm-ui.danger > h3 {
  color: theme-color-level(danger, 0) !important;
  font-weight: 600;
}

.confirm-ui.primary > h3 {
  color: theme-color-level(primary, 0) !important;
  font-weight: 600;
}

.confirm-ui > p {
  color: #000;
}

.cancel-ui > h3 {
  color: #aaa;
  font-weight: 600;
  margin: 10px auto;
}

.cancel-ui > p {
  color: #000;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
  z-index: 20000 !important;
}

.conteudoCard .slick-track {
  margin-left: 0;
}

.btn-bold.btn {
  font-weight: bold;
}

.contentChart {
  max-height: 200px;
}

.pickerContent {
  padding: 5px;
}

.rmp-container.rmp-table.form-control.show {
  border: none;
  padding: 0px;
}

.mh-320 {
  min-height: 320px;
}
.mh-400 {
  max-height: 400px;
}

.pl-10 {
  padding-left: 14rem !important;
}

.detailsChart p {
  font-size: 20px;
}

.iconRating {
  width: 30px;
  height: 20px;
}

.totalRating {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 19px;
  text-transform: uppercase;
  color: #000000;
  padding-left: 13px;
}
.imgPerfil .fotoPerfil {
  width: 100px;
  height: 100px;
}

.novoColaborador {
  width: 100%;
  display: inline-block;
}
.numeroOs:hover {
  cursor: pointer;
  text-decoration: underline;
}
.helperPosto {
  background-color: #f8f8f8;
}
.noDecoration,
.noDecoration:hover {
  text-decoration: none;
}

.cardDefault .destaqueTxtCard.financeiro {
  font-size: 34px;
}

.b-botton {
  border-bottom: 1px solid #dee2e6 !important;
}

.logo-home{
  width: 20vw;
}

.logo-container{
  position: absolute;
  padding-top: 125px;
}

@media (max-height: 900px) {
  .logo-container{
    position: absolute;
    padding-top: 30px;
  }
}

@media (max-width: 575px) {
  .w-xs-100 {
    width: 100%;
  }
  .logo-home {
    width: 51vw;
  }

  .logo-container{
    position: relative;
    padding-top: 10px;
  }

  .back-index {
    background: none;
  }

  .back-entrar {
    background: none;
  }
  .border-sm-left {
    border-top: 1px solid $cinza4;
    border-left: none;
    padding-top: 20px;
  }

  #termo h1 {
    font-size: 21px;
  }

  .btn.btn-sm,
  .btn-group-sm > .btn {
    padding: 12px 10px;
    font-size: 11px;
  }
  .cardDefault .destaqueTxtCard.financeiro {
    font-size: 25px;
  }
  .collapses .etapa .topo-etapa {
    height: 100px;
  }
  .collapses .etapa .topo-etapa .autor {
    padding-left: 0rem !important;
  }
  .collapses .etapa .titulo-collapse {
    width: 100%;
  }
  .collapses .etapa .titulo-collapse h1 {
    margin-bottom: 0px;
  }
  .collapses .etapa .topo-etapa::after {
    margin-left: 10px;
    height: 25px;
    content: '';
    margin-bottom: 0px;
  }
}

.card-concluido{
  background-color: #054a31;
  color:#FFF;
height: 100%;
  text-align: center;

}
.card-concluido h2{
 font-size: 0.9em;
  color:#FFF;
}
.card-concluido hr{
 border-top: 2px solid #075636;

}

.card-concluido p{
  text-align: left;
  color:#FFF;
  font-size: 0.8em;
}
.card.card-concluido .card-block{
  padding: 25px 0px;

}
.card-concluido .item-concluido
{
  padding: 25px 10px;
}
.card-concluido .item-concluido:nth-child(even) {
  background-color: #075636; /* Altere para a cor que desejar */
}

/* Estilizando o background dos itens concluídos ímpares */
.card-concluido .item-concluido:nth-child(odd) {
  background-color: #072f18; /* Altere para a cor que desejar */
}

.card-concluido h3{
  font-size: 2em;
  color:#fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.card-concluido h3 span{
  font-size: 3em;
  color:#fff;
  padding: 0px 15px;
}
