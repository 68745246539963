@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:400,500i,700&display=swap');
/* Criar aqui as customizações */
$theme-colors: (
  'primary': #1B5959,
  'secondary': #DCF2E6,
  'danger': #ea3a45,
  'warning': #d4dd66,
  'light': #ffffff,
  'success': #85cd63,
  'info': #ffc100,
  'destaque': #85cd63,
);
$txt-btn-primary: #fff;
$border-color-btn-primary: theme-colors('primary');
$color-icon-back: #000;

$branco: #ffffff;
$cinza1: #aaaaaa;
$cinza2: #f6f6f6;
$cinza3: #e0e0e0;
$cinza4: #dddddd;
$cinza5: #999999;
$preto: #000000;

$pct1: #85cd63;
$pct2: #3e67b3;
$pct3: #d4dd66;
$pct4: #3e67b3;
$pct5: #ffc100;
$pct6: #222222;

$body-bg: $branco;

$font-xl: 32px;

$font-lg: 24px;

$font-md: 18px;

$font-sm: 16px;

$font-df: 14px;

$font-xs: 11px;

$font-btn: 10px;

$border-radius-btn: 20px;

$border-radius: 10px;

$font-family-sans-serif: 'Roboto', sans-serif;

$border-color: $cinza4;

// Imagens
$logomarca: './img/logo.svg';
$bg-login: './img/BackGroundEntrar.svg';
$bg-content: '';
$bg-back-index: './img/index-back.svg';
$bg-size: contain;
$bg-align: top right;
$bg-align-content: top right;
$font-color-login: #212529;
$icon-add-more: './img/bt_add.svg';
$bg-img-avatar: './img/avatarDefault.svg';
$bg-img-add-avatar: './img/addColaborador.svg';
$min-height-logo: 130px;

@function theme-color-level($color-name: 'primary', $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, #000, #fff);
  $level: abs($level);
  @return mix($color-base, $color, $level * $theme-color-interval);
}
