/* ---- CADASTRO---- */

.cadastro {
  max-width: 940px;
  margin: auto;
  padding-top: 200px;
}

.cardDefault .cadastro {
  padding-top: 0;
}

.cardDefault .cadastro h3 {
  color: #aaa;
  margin: 40px 4px;
}

.cadastro p.check {
  font-weight: 600;
  color: #85cd63;
  margin-bottom: 40px;
}

.cadastro_lineVert {
  border-bottom: 1px solid #ddd;
  margin-top: 40px;
}

.cadastro_foto {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin: auto;
}

.cardDefault .cadastro_foto {
  margin: 0 auto 30px auto;
}

.termo {
  padding-bottom: 140px;
}

.cardDefault .termo {
  padding-bottom: 20px;
}

.termos {
  background-color: #ffffff;
  padding: 30px 20px 60px;
  min-height: 100px;
  max-width: 940px;
  margin-left: -470px;
  left: 50%;
  border-top: solid 1px #ddd;
}

.icon-contract {
  max-width: 90px;
}

/* WIZARD */

.topoFixo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 310px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 1) 92.44%,
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.wizard-container {
  margin: auto;
}

.card {
  background-color: #ffffff;
  color: #252422;

  margin-bottom: 20px;
  position: relative;
}

.wizard-card {
  min-height: 410px;
  border: none;
  border-radius: 0;
  min-width: 100%;
}

/* .cardDefault .wizard-card {
        border-radius: 0
    } */

.wizardNav {
  margin: auto;
}

.wizard-card .wizard-navigation {
  /* max-width: 940px; */
  position: relative;
}

.wizard-navigation {
  /* max-width: 940px; */
  position: relative;
}

.wizard-card .wizard-navigation .progress-with-circle {
  position: relative;
  top: 40px;
  z-index: 50;
  height: 4px;
}

.wizard-navigation .progress-with-circle {
  position: relative;
  top: 40px;
  z-index: 50;
  height: 4px;
}

.nav-pills {
  background-color: #dddddd;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 40px;
  text-align: center;
}

.nav-pills > li > a {
  padding: 0;
  max-width: 41px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: relative;
  top: -31px;
  z-index: 100;
}

.wizard-card .tit {
  height: 20px;
  margin-top: 0px;
  font-size: 11px;
  visibility: hidden;
}

.wizard-card a.linkWizard {
  text-decoration: none;
  font-style: normal;
  font-size: 11px;
  font-weight: normal;
}

.wizard-card .icone-circle .num {
  position: absolute;
  z-index: 1000;
  left: 1px;
  right: 0px;
  font-size: 14px;
  top: 4px;
  color: #aaaaaa;
}

.nav-pills > li.checked > a .num {
  color: #fff;
  font-size: 20px;
  top: 1px;
}

.nav-pills > li.active > a .num,
.nav-pills > li.active > a:hover .num,
.nav-pills > li.active > a:focus .num {
  color: #27292b;
  font-size: 20px;
  top: 1px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.wizard-card .icone-circle {
  font-size: 20px;
  border: 2px solid #dddddd;
  text-align: center;
  border-radius: 50%;
  color: #aaaaaa;
  font-weight: 600;
  width: 30px;
  height: 30px;
  background-color: #dddddd;
  margin: 0px auto;
  position: relative;
  top: -2px;
}

.wizard-card .nav-pills > li.active > a:after {
  background-color: #27292b;
}

.wizard-card .nav-pills > li.active > a {
  color: #27292b;
}

.wizard-card .nav-pills > li.active .icone-circle {
  border-color: #27292b;
  background-color: #fff;
  color: #27292b;
}

.wizard-card .nav-pills > li.active a .tit {
  visibility: visible;
}

.wizard-card .wizard-navigation .progress-bar {
  background-color: #85cd63;
  height: 3px;
}

.wizard-card .tab-content {
  min-height: 420px;
  padding: 70px 20px 10px;
}

.nav-pills > li > a:after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -1px;
  top: -4px;
  transform: scale(0);
  transition: 0.2s all linear;
}

.wizard-card .nav-pills > li.checked .icone-circle {
  border-color: #85cd63;
  background-color: #85cd63;
  color: #fff;
}

/* TAREFAS */

.lineTarefa {
  border-top: 1px solid #ddd;
  min-height: 70px;
  margin: 0 10px;
  padding: 14px 0;
}

.lineTarefa:last-child {
  border-bottom: 1px solid #ddd;
}

.lineTarefa a.enviar {
  text-decoration: underline !important;
  color: #2b60e8 !important;
}

/* MOBILE */

@media (max-width: 1024px) {
  .btn {
    min-width: 0;
  }
  .cadastro_lineVert {
    margin-top: 10px;
  }
  .topoFixo {
    min-height: 210px;
  }
  /* Wizard */
  .wizard-card .tit {
    min-width: 82px;
  }
  .wizard-card .tab-content {
    padding: 50px 20px 10px;
  }
  .termo {
    padding-bottom: 100px;
  }
  .cardDefault .termo {
    padding-bottom: 10px;
  }
  .termos {
    background-color: #ffffff;
    padding: 20px 36px 10px;
    min-height: 100px;
    width: 100%;
    margin-left: auto;
    max-width: 100%;
    left: 0;
  }
}

@media (max-width: 575px) {
  .cardDefault .cadastro h3 {
    color: #aaa;
    margin: 20px auto 20px auto;
  }
}
