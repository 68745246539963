body{
    margin-top:20px;
    background:#FAFAFA;
}
.order-card {
    color: #fff;
}

.card.bg-c-blue {
   border-bottom: 10px solid #4099ff;
}

.card.bg-c-green {
    border-bottom: 10px solid #2ed8b6;
}

.card.bg-c-yellow {

    border-bottom: 10px solid #FFB64D;
}
.card.bg-c-pink-faturamento{

    border-bottom: 10px solid #ff00a4;

}
.card.bg-c-pink {

    border-bottom: 10px solid #FF5370;

}
.card.bg-c-gray {

    border-bottom: 10px solid #bebdc3;

}
.card h6{
    font-size: 35px;
}

.card.bg-c-blue h6 {
    color:#4099ff;
}

.card.bg-c-green h6{
    color:#2ed8b6;
}

.card.bg-c-yellow h6{

    color:#FFB64D;
}

.card.bg-c-pink h6{

    color:#FF5370;

}
.card.bg-c-gray h6{

    color: #bebdc3;

}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #000 !important;
}

.card .card-block {
    padding: 25px 25px 5px 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}


.bullet{
    display: inline-block;
    height: 15px;
    width: 15px;
    border: 1px solid transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.bullet.criado{
    background-color:  #bebdc3;
}
.bullet.em-separacao{
    background-color: #FFB64D;
}
.bullet.a-faturar{
    background-color: #4099ff;
}
.bullet.faturado {
    background-color: #2ed8b6;
}
.bullet.atrasado{
    background-color: #FF5370;
}
.bullet.atrasado-faturamento {
    background-color: #ff00a4;
}




