/* ---- CROP---- */

// .nav-item .badge {
//   font-family: 'Dosis', sans-serif;
//   font-weight: bold;
//   position: absolute;
//   top: -5px;
//   right: -10px;
//   padding: 4px 2px;
//   font-size: 12px;
//   color: #fff;
// }

.topoTab .sts {
  line-height: 41px;
}

.conteudoTab .borderForm {
  border: 1px solid #bbbbbb;
  /* border-radius: 4px; */
  margin-bottom: -1px;
  padding: 15px;
}

.conteudoTab .borderForm .form-group.borderTop {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.conteudoTab .form-row {
  margin-left: 0px;
  margin-right: 0px;
}

/* Manutenção */

.border100 {
  width: 100px;
  height: 100px;
  border: 1px solid #f1f1f1;
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.border100 img {
  max-height: 90px;
}

/* Historico */

.historico ul {
  font-family: 'Dosis', sans-serif;
  padding: 0px;
  margin: 0;
  list-style: none;
  position: relative;
  margin-left: 15px;
}

.historico ul li::after {
  content: ' ';
  height: 100%;
  width: 0px;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 2.5em;
  z-index: -1;
}

.historico ul li > div {
  display: inline-block;
  margin: 1em 0;
  vertical-align: top;
  margin-top: -10px;
}

.historico .autor img {
  height: 24px;
}

.historico ul li .bullet {
  width: 30px;
  height: 30px;
  font-size: 16px;
  border-radius: 50%;
  color: #fff;
  left: -16px;
  position: relative;
  text-align: center;
}

.historico ul li.verde,
li.txtGreen {
  border-left: 3px solid #85cd63;
}

.historico ul li.azul,
li.txtBlue {
  border-left: 3px solid #2b60e8;
}

.historico ul li.vermelho,
li.txtRed {
  border-left: 3px solid #ea3a45;
}

.historico ul li.branco,
li.txtDarkGray {
  border-left: 3px solid #27292b;
}

.historico ul li.verde,
li.txtGreen .bullet {
  background: #85cd63;
  border: 3px solid #85cd63;
}

.historico ul li.azul,
li.txtBlue .bullet {
  background: #2b60e8;
  border: 3px solid #2b60e8;
}

.historico ul li.vermelho,
li.txtRed .bullet {
  background: #ea3a45;
  border: 3px solid #ea3a45;
}

.historico ul li.branco,
li.txtDarkGray .bullet {
  background: #fff;
  border: 3px solid #27292b;
  color: #27292b !important;
}

.historico ul li:last-child {
  border-left: 3px solid transparent;
}

/* .historico ul li {} */

.historico ul li .titulo {
  font-size: 24px;
  line-height: 30px;
}

.historico ul li .data {
  font-size: 11px;
  font-weight: bold;
  color: #4c4c4c;
}

.nfinput .icon-foto {
  color: #4c4c4c;
}

.txtBlue .icon-foto {
  color: #2b60e8;
}

.nfinput:hover {
  color: #aaaaaa;
}

.historico ul li .autor .por {
  font-size: 11px;
  color: #aaaaaa;
}

.historico ul li .descricao {
  color: #000;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.mt-10 {
  margin-top: 10px;
}

/* MOBILE */

@media (max-width: 1024px) {
}

@media (max-width: 575px) {
  .topoTab h1 {
    font-size: 20px;
    line-height: 25px;
    min-height: 30px;
  }
  .topoTab h4 {
    font-size: 10px;
    min-height: 30px;
  }
  .topoTab .sts {
    line-height: 13px;
    min-height: 30px;
  }
  .historico ul li > div {
    width: 100%;
  }
}

.tablePecas.table th,
.tablePecas.table td {
  padding: 0.75rem 0 0.75rem 0;
}

.tablePecas.table th,
.tablePecas.table td {
  padding: 0.75rem 0 0.75rem 0;
}

.react-bs-container-body table tbody tr.border2 {
  border-bottom: 2px solid #000000;
}

.react-bs-table table.tablePecas td,
.react-bs-table table.tablePecas th {
  white-space: pre-wrap;
}

.react-bs-container-body table.tablePecas tbody tr:last-child {
  border-bottom: 2px solid #000000;
  padding-bottom: 10px;
}
