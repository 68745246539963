/* MENU LATERAL */

.navbar .sidebar-toggle {
  float: left;
  margin-left: -4px;
}

.navbar .sidebar-toggle + .navbar-brand {
  margin-left: 0;
}

.sidebar {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 270px;
  background: #ffffff;
  z-index: 1020;
  overflow: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.sidebar.sidebar-static {
  position: absolute;
}

.sidebar i {
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 3px;
  vertical-align: middle;
  width: 20px;
  display: inline-block;
}

.sidebar i.icon-foto {
  margin-right: 3px;
}

.sidebar i.fa {
  vertical-align: baseline;
}

.sidebar ul.menu {
  margin: 0;
  list-style-type: none;
  padding: 20px 0;
  margin-bottom: 40px;
}

.sidebar ul.subMenu {
  margin: 0;
  list-style-type: none;
  padding: 0px 0;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content .hideNano {
  display: none;
}

.iconSubMenu {
  float: right;
  margin: 8px 0px 0px 0px;
}

.sidebar ul.subMenu > li {
  background-color: #ffc1001c;
  width: 100%;
}

.sidebar .nano-content > ul {
  width: 100%;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.sidebar ul.menu li a {
  color: #000;
}

.sidebar ul.menu li:last-child {
  position: fixed;
  bottom: 0;
  background-color: white;
}

.sidebar ul.subMenu li:last-child {
  position: inherit;
  /* margin-bottom: 50px; */
  background-color: #ffc1001c;
}

.sidebar ul.menu li:last-child a {
  color: #000;
}

.sidebar ul.subMenu li:last-child a:hover {
  color: #000;
  background-color: #ffffff !important;
}

.sidebar ul.menu li:after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #ededed;
}

.sidebar ul.menu li > ul.subMenu > li:after {
  content: '';
  display: block;
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c4c4c4;
}

.sidebar .nano-content > ul li.label {
  font-size: 14px;
  line-height: 40px;
  color: rgba(160, 180, 200, 0.55);
  text-transform: capitalize;
  font-weight: normal;
}

.sidebar .nano-content > ul li.content {
  padding: 10px;
  color: #d8d8d8;
}

.sidebar .nano-content > ul li.content .progress {
  margin-top: 0.7em;
}

/* .sidebar > ul.menu li > ul.subMenu {
  display: block;
  background: rgba(0, 0, 0, 0.2) !important;
} */

.sidebar > ul li > ul.open.subMenu {
  display: block;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li > ul {
  display: none;
  width: 200px;
}

/* Animação links */

.sidebar .nano-content > ul li > a {
  display: block;
  cursor: pointer;
  padding: 6px;
  line-height: 35px;
  border-bottom: none;
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  letter-spacing: 0em;
  -webkit-transition: font-size 0.1s 0.3s ease-in-out;
  transition: font-size 0.1s 0.3s ease-in-out;
}

.sidebar .nano-content > ul li > a > .sidebar-collapse-icon {
  float: right;
  margin-right: 5px;
  font-size: 10px;
  margin-top: 10px;
  transition: -webkit-transform 0.3s ease;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}

.sidebar .nano-content > ul li > a > .badge {
  font-weight: normal;
  position: absolute;
  right: 45px;
  top: 15px;
}

.sidebar .nano-content > ul li:hover > a,
.sidebar .nano-content > ul li.active > a,
.sidebar .nano-content > ul li.open > a {
  position: relative;
  /* background-color: #f5f5f5; */
}

.sidebar .nano-content > ul li.active > a::before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.sidebar .nano-content > ul li.open > a > .sidebar-collapse-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.sidebar .nano-content > ul > li > a.active {
  opacity: 0.3;
  background-color: #ebebeb;
  cursor: default;
}

.sidebar .nano-content > ul > li.active {
  cursor: default;
}

.sidebar {
  -webkit-transition: left 300ms ease-in-out, width 300ms ease-in-out;
  transition: left 300ms ease-in-out, width 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-hide-to-small {
  position: absolute;
  width: 70px;
  left: -70px;
  top: 0px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small,
.sidebar-hide .sidebar.sidebar-hide-to-small .nano,
.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content {
  overflow: visible;
  box-shadow: none;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul {
  width: 70px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul .label,
.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul .content {
  display: none;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul .badge {
  display: none;
}

.sidebar.sidebar-hide-to-small .nano-content > ul > li > a {
  padding-left: 30px;
}

.sidebar.sidebar-hide-to-small .nano-content > ul > li > ul > li > a {
  padding-left: 40px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li > a {
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li > a > i {
  width: auto;
  margin-right: 0px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li > a,
.sidebar-hide
  .sidebar.sidebar-hide-to-small
  .nano-content
  > ul
  > li
  > a
  > .sidebar-collapse-icon {
  font-size: 0;
}

.sidebar-hide
  .sidebar.sidebar-hide-to-small
  .nano-content
  > ul
  > li
  > a
  > .badge {
  position: absolute;
  right: 3px;
  top: 3px;
}

.sidebar-hide
  .sidebar.sidebar-hide-to-small
  .nano-content
  > ul
  > li:last-child {
  position: fixed;
  width: 100%;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li:after {
  border-bottom: 1px solid transparent;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul > li > ul {
  display: none;
  width: 200px;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .nano-content > ul li:hover > ul {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  -webkit-box-shadow: 0 2px 2px 0 #e7e7e7;
  box-shadow: 0 2px 2px 0 #e7e7e7;
  /* display: block !important; */
  visibility: visible;
}

.sidebar.sidebar-push ~ .content-wrap {
  -webkit-transform: translateX(250px) translateZ(0);
  transform: translateX(250px) translateZ(0);
  transition: -webkit-transform 300ms ease-in-out, margin-left 300ms ease-in-out;
  -webkit-transition: margin-left 300ms ease-in-out,
    -webkit-transform 300ms ease-in-out;
  transition: margin-left 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, margin-left 300ms ease-in-out;
  transition: transform 300ms ease-in-out, margin-left 300ms ease-in-out,
    -webkit-transform 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-push ~ .content-wrap {
  -webkit-transform: translateX(0) translateZ(0);
  transform: translateX(0) translateZ(0);
}

.content-wrap {
  // margin-left: 0px;
  margin-top: 100px;
  // margin-right: 0px;
  // margin-bottom: 15px;
  // height: calc(100vh - 145px);
}

// .sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .content-wrap {
//   margin-left: 81px;
//   margin-right: 11px;
// }

// .sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .header {
//   margin-left: 80px;
// }

.sidebar.sidebar-hide-to-small .logo {
  display: inline-block;
  text-align: center;
  width: 100%;
  // height: 65px;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0;
  transition: padding-top 0.3s ease-in-out;
}

// .sidebar-hide .sidebar.sidebar-hide-to-small .logo {
//   padding: 15px 20px 0 20px;
//   width: 70px;
// }
 .logo .logoMarca {
  background: url($logomarca) no-repeat;
  min-height: $min-height-logo;
  background-position-x: center;
  background-size: contain;

}
 .logo .logoMarca {
  background: url($logomarca) no-repeat;
  min-height: $min-height-logo;
  background-position-x: center;
  background-size: contain;
}

/* Animação Logo */

// .sidebar.sidebar-hide-to-small .logo img {
//   width: 100px;
//   transition: width 0.3s ease-in-out;
// }

// .sidebar-hide .sidebar.sidebar-hide-to-small .logo img {
//   width: 20px;
// }

.sidebar.sidebar-hide-to-small .iconeCollapse {
  display: block;
  transition: display 300ms ease-in-out;
  float: right;
  padding-top: 15px;
  padding-right: 20px;
  position: fixed;
  right: 0;
  z-index: 20000;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .iconeCollapse {
  display: none;
}

.sidebar.sidebar-hide-to-small .iconeCollapse img {
  height: 15px;
  width: 17px;
  cursor: pointer;
}

/* Animação Foto perfil */

.sidebar.sidebar-hide-to-small .fotoPerfil {
  width: 90px;
  height: 90px;
  transition: width 0.3s ease-in-out;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .fotoPerfil {
  width: 40px;
  height: 40px;
}

.sidebar.sidebar-hide-to-small .cargo {
  font-size: 11px;
}

/* Animação dos dados do perfil */

.sidebar.sidebar-hide-to-small .perfil {
  width: 100%;
  height: 195px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .perfil {
  width: 70px;
  padding-top: 0;
  margin-top: -20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 215px;
}

.sidebar.sidebar-hide-to-small .perfil .imgPerfil {
  height: 94px;
  display: block;
}
.sidebar-hide .sidebar.sidebar-hide-to-small .perfil .imgPerfil {
  display: none;
}

.sidebar.sidebar-hide-to-small .dadosPerfil {
  overflow: hidden;
  opacity: 1;
  filter: opacity(1);
  transition: opacity 0.3s 0.3s ease-in-out;
}

.sidebar-hide .sidebar.sidebar-hide-to-small .dadosPerfil {
  pointer-events: none;
  opacity: 0;
  filter: opacity(0);
  transition: opacity 0.3s 0.3s ease-in-out;
}

/* Hide on Content Click */

.sidebar.sidebar-overlap-content ~ .content-wrap:after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  -webkit-transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.sidebar-hide .sidebar.sidebar-overlap-content ~ .content-wrap:after {
  visibility: hidden;
  opacity: 0;
}

.sidebar .nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sidebar .nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sidebar .nano > .nano-content:focus {
  outline: thin dotted;
}

.sidebar .nano > .nano-content::-webkit-scrollbar {
  display: none;
}

.sidebar .nano.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}

.sidebar .nano > .nano-pane {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 5px;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden\9;
  opacity: 0.01;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 5px;
}

.sidebar .nano > .nano-pane > .nano-slider {
  position: relative;
}

.sidebar .nano:hover > .nano-pane,
.sidebar .nano > .nano-pane.active,
.sidebar .nano > .nano-pane.flashed {
  visibility: visible\9;
  opacity: 0.99;
}

/* Style */

.sidebar-hide .sidebar.sidebar-hide-to-small {
  position: fixed;
}

/*    Header
---------------*/
.hamburger-content {
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.hamburger .line {
  background-color: #000;
  display: block;
  height: 2px;
  margin: 3px -5px 4px 5px;
  width: 17px;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header {
  background: #ffffff;
  z-index: 1010;
  height: 100px;
  transition: margin-left 300ms ease-in-out 0s;
  margin-right: 12px;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
}

.header > .container-fluid {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.header .linhaTopo {
  height: 100px;
}

.header-icon {
  font-size: 14px;
  padding: 12px 20px 12px 20px;
  color: #000;
  position: relative;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  border-left: 1px solid #ddd;
}

.header-icon.disabled {
  cursor: default;
  color: #c4c4c4;
}

.header-icon i {
  font-size: 26px;
  margin-right: 15px;
  position: relative;
}

.header-icon.active {
  background: rgba(255, 255, 255, 0.3);
  color: #c4c4c4;
}

/* DropDown */

.header-icon.active .drop-down {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

.drop-down {
  background: #ffffff;
  color: #000;
  visibility: hidden;
  opacity: 0;
  width: 450px;
  position: absolute;
  right: 0;
  top: 83px;
  transform: translateY(-10px);
  transition: all 0.4s ease-in-out;
  border: 0px;
  z-index: 2003;
  border: 1px solid #eaeaea;
  border-top: 0px solid transparent;
  -webkit-box-shadow: 0px 11px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 11px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 11px 10px 0px rgba(0, 0, 0, 0.15);
}

.header-left .drop-down {
  left: 0;
}

.dropdown-content-body ul {
  list-style: none;
  padding-left: 0px;
}

.dropdown-content-body li {
  border-bottom: 1px solid #eef5f9;
}

.dropdown-content-body li a {
  display: inline-flex;
  text-decoration: none;
  font-style: normal;
  color: #252525;
  font-weight: normal;
  padding: 15px;
}

.dropdown-content-body li.active,
.dropdown-content-body li:focus,
.dropdown-content-body li:hover {
  background-color: #f9f9f9;
  /* border-top: 1px solid #eef5f9; */
}

.dropdown-content-body .listaTarefas li a.active,
.dropdown-content-body .listaTarefas li a:focus,
.dropdown-content-body .listaTarefas li a:hover {
  color: theme-color-level(danger, 2);
}

.dropdown-content-body .listaMensagens li a.active,
.dropdown-content-body .listaMensagens li a:focus,
.dropdown-content-body .listaMensagens li a:hover {
  color: theme-color-level(primary, 2);
}

.dropdown-content-body li:last-child {
  padding: 0px 0px 0px 15px;
  border-bottom: 0;
  text-align: right;
}

.dropdown-content-body li i {
  padding: 5px 0px;
  font-size: 15px;
}

.dropdown-content-body li .numeroOS {
  font-weight: bold;
  text-align: left;
}

.dropdown-content-body li .pecas {
  text-align: left;
}

.dropdown-content-body li .local {
  text-align: left;
}

.dropdown-content-body li:last-child a {
  text-decoration: underline;
  font-style: italic;
  font-size: 12px;
  padding-right: 6px;
}

.dropdown-content-body li:last-child i {
  font-size: 11px;
  text-decoration: none;
}

.avatar-img {
  border-radius: 100px;
  width: 50px;
  position: relative;
  top: -3px;
}

.user-avatar {
  font-size: 14px;
  font-weight: 700;
}

.more-link {
  font-size: 12px;
  /* color: #5873fe; */
  display: inline-block;
}

/* 
.dropdown-profile {
  width: 200px;
}

.dropdown-profile .trial-day {
  font-size: 11px;
  padding-top: 2px;
   color: #5873fe;
}

.dropdown-profile li {
  padding: 7px 15px;
}

.dropdown-profile li a {
  display: block;
  color: #373757;
}

.dropdown-profile li a.active,
.dropdown-profile li a:focus,
.dropdown-profile li a:hover {
  color: #373757;
}

.dropdown-profile li a span {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

.dropdown-profile li a i {
  margin-right: 5px;
  font-size: 14px;
}

.dropdown-profile li:last-child {
  padding: 7px 15px;
} */

.dropdown-profile li:last-child a {
  text-decoration: underline;
  font-style: italic;
  font-size: 12px;
}

.dropdown-profile li:last-child i {
  font-size: 11px;
}

.header-link {
  position: relative;
  top: -3px;
  font-size: 14px;
}

.listaMensagens .badge {
  position: inherit;
}

.listaMensagens .resumoMsg {
  text-align: left;
  padding: 0px 10px;
}

.listaMensagens .msg {
  font-size: 13px;
}

.listaMensagens .nomeMsg {
  font-weight: bold;
  margin-bottom: 5px;
}

.listaMensagens .dataMsg {
  min-width: 60px;
}

.listaMensagens .data {
  color: #76b956;
  font-size: 10px;
  margin-bottom: 10px;
}

/**
* FOOTER
*/

footer {
  bottom: 0;
  position: fixed;
  right: 17px;
  display: block;
  z-index: 1009;
}

footer p {
  font-size: 12px;
  margin-bottom: 0.2rem;
}

footer a {
  font-size: 13px;
}

.hideDesktop {
  display: none;
}

/*  Responsive*/

@media (max-width: 1024px) {
  .header > .container-fluid {
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
  }
  .hideMobile {
    display: none;
  }
  .hideDesktop {
    display: block;
  }

  // .header .linhaTopo {
  //   height: 65px;
  //   margin-top: -10px;
  // }
  .drop-down {
    top: 55px;
    position: fixed;
    left: 0;
    width: 100%;
  }
  .dropdown-content-body li a {
    padding: 15px;
    font-size: 12px;
  }
  .sidebar-hide .sidebar.sidebar-hide-to-small {
    left: -70px;
  }
  .header {
    margin: 0px;
    height: 100px;
    padding: 0px;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .header-icon {
    padding: 14px;
    border: none;
  }
  .header-icon i {
    font-size: 18px;
    margin-right: 8px;
  }
  .sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .header {
    margin-left: 0px;
  }
  .sidebar {
    width: 100%;
  }
  .sidebar .nano-content > ul {
    padding: 0px;
  }
  ul.menu > li:last-child {
    /* position: relative; */
    width: 100%;
    background-color: #f02d34;
  }

  .sidebar ul.menu {
    margin-bottom: 10px;
  }

  .sidebar ul.menu > li:last-child:after {
    border-bottom: none;
  }
  .sidebar.sidebar-hide-to-small .logo {
    padding: 16px 35px 0 20px;
  }

  .sidebar.sidebar-hide-to-small .perfil {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 14px;
  }
  .sidebar.sidebar-hide-to-small .cargo {
    margin-bottom: 0px;
  }
  .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .content-wrap {
    margin-left: 0px;
    padding: 0px 10px;
    margin-top: 62px;
  }
  .sidebar-hide .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .content-wrap {
    margin-left: 0px;
    margin-top: 100px;
  }
  .sidebar .nano-content > ul li > a {
    line-height: 30px;
  }
  .card {
    display: inline-block;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .sidebar.sidebar-shrink.sidebar-hide-to-small ~ .content-wrap {
    padding: 0px 0px;
  }
  .sidebar.sidebar-hide-to-small .nano-content > ul > li:nth-last-child(2) {
    padding-bottom: 50px;
  }
  .sidebar ul.subMenu li:last-child {
    margin-bottom: 0px;
  }
}
