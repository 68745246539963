/* ---- CARD CHAT ABERTO---- */

.containerChat {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  height: 500px;
}

.message {
  margin: auto;
  padding: 0px 15px 0px 15px;
}

.message h5 {
  text-align: right;
  margin: 8px 20px 20px;
  font-size: 0.75rem;
  color: #aaaaaa;
}

.message .col-auto {
  max-width: 80%;
}

.message .fotoPerfil {
  width: 75px;
 height: 75px;
}

.message .msg {
  max-width: 80%;
}

.message.my h5 {
  text-align: left;
}

.message.my .boxMessage {
  background-color: #f7f7f7;
}

.boxMessage {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 14px 20px;
  margin: 0 10px;
}

.boxMessage p {
  margin: 0;
}

.fotoUser {
  max-width: 70px;
}

.lineVert {
  border-bottom: 1px solid #ddd;
  margin: auto 10px;
}

.marcador {
  margin: 0;
  color: #85cd63;
  text-transform: inherit;
}

.inputMessage {
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.btUserChat {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 20px 0 0 20px;
  width: 40px;
  position: relative;
}

.btUserChat:active {
  background-color: #f7f7f7;
  color: #888;
}

.btUserChat h2 {
  position: absolute;
  margin: 0;
  bottom: 6px;
  left: 12px;
}

textarea.form-control.chat {
  height: 2.25rem;
  border-radius: 20px;
  margin-left: -1px;
}

.icon-anexo {
  font-size: 20px;
}

.sendMessage {
  min-width: 40px !important;
  min-height: 40px;
  padding: 0;
  font-size: 24px;
}

.borderInput .itemSearch {
  width: 100%;
}

.mention .avatarContainer img{
  width: 40px;
  height: 40px;
}

.mention .bg-img-avatar.fotoPerfil{
  width: 40px;
  height: 40px;
}

/* MOBILE */

@media (max-width: 1024px) {
  .sendMessage {
    min-width: 30px !important;
    min-height: 30px;
    font-size: 18px;
  }
}
