.fixed-table-container .bs-checkbox,
.fixed-table-container .no-records-found {
  text-align: center;
}

.fixed-table-body thead th .th-inner,
.table td,
.table th {
  box-sizing: border-box;
}

.bootstrap-table .table {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ddd;
  border-collapse: collapse !important;
  border-radius: 1px;
}

.bootstrap-table .table:not(.table-condensed),
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td,
.bootstrap-table .table:not(.table-condensed) > tbody > tr > th,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > td,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > th,
.bootstrap-table .table:not(.table-condensed) > thead > tr > td {
  padding: 8px;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td,
.bootstrap-table .table.table-no-bordered > thead > tr > th {
  border-right: 2px solid transparent;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td:last-child {
  border-right: none;
}

.fixed-table-container {
  position: relative;
  clear: both;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.fixed-table-container.table-no-bordered {
  border: 1px solid transparent;
}

.fixed-table-footer,
.fixed-table-header {
  overflow: hidden;
}

.fixed-table-footer {
  border-top: 1px solid #ddd;
}

.fixed-table-body {
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
}

.fixed-table-container table {
  width: 100%;
}

.fixed-table-container thead th {
  height: 0;
  padding: 0;
  margin: 0;
  border-left: 1px solid #ddd;
}

.fixed-table-container thead th:focus {
  outline: transparent solid 0;
}

.fixed-table-container thead th:first-child {
  border-left: none;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.fixed-table-container tbody td .th-inner,
.fixed-table-container thead th .th-inner {
  padding: 8px;
  line-height: 24px;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-table-container thead th .sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.fixed-table-container thead th .both {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC');
}

.fixed-table-container thead th .asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.fixed-table-container thead th .desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.fixed-table-container th.detail {
  width: 30px;
}

.fixed-table-container tbody td {
  border-left: 1px solid #ddd;
}

.fixed-table-container tbody tr:first-child td {
  border-top: none;
}

.fixed-table-container tbody td:first-child {
  border-left: none;
}

.fixed-table-container tbody .selected td {
  background-color: #f5f5f5;
}

.fixed-table-container .bs-checkbox .th-inner {
  padding: 8px 0;
}

.fixed-table-container input[type='radio'],
.fixed-table-container input[type='checkbox'] {
  margin: 0 auto !important;
}

.fixed-table-pagination .pagination-detail,
.fixed-table-pagination div.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fixed-table-pagination div.pagination .pagination {
  margin: 0;
}

.fixed-table-pagination .pagination a {
  padding: 6px 12px;
  line-height: 1.428571429;
}

.fixed-table-pagination .pagination-info {
  line-height: 34px;
  margin-right: 5px;
}

.fixed-table-pagination .btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.fixed-table-pagination .dropup .dropdown-menu {
  margin-bottom: 0;
}

.fixed-table-pagination .page-list {
  display: inline-block;
}

.fixed-table-toolbar .columns-left {
  margin-right: 5px;
}

.fixed-table-toolbar .columns-right {
  margin-left: 5px;
}

.fixed-table-toolbar .columns label {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
}

.fixed-table-toolbar .bs-bars,
.fixed-table-toolbar .columns,
.fixed-table-toolbar .search {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 34px;
}

.fixed-table-pagination li.disabled a {
  pointer-events: none;
  cursor: default;
}

.fixed-table-loading {
  display: none;
  position: absolute;
  top: 42px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  text-align: center;
}

.fixed-table-body .card-view .title {
  font-weight: 700;
  display: inline-block;
  min-width: 30%;
  text-align: left !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.fixed-table-toolbar .dropdown-menu {
  text-align: left;
  max-height: 300px;
  overflow: auto;
}

.fixed-table-toolbar .btn-group > .btn-group {
  display: inline-block;
  margin-left: -1px !important;
}

.fixed-table-toolbar .btn-group > .btn-group > .btn {
  border-radius: 0;
}

.fixed-table-toolbar .btn-group > .btn-group:first-child > .btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fixed-table-toolbar .btn-group > .btn-group:last-child > .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bootstrap-table .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ddd;
}

.bootstrap-table .table thead > tr > th {
  padding: 0;
  margin: 0;
}

.bootstrap-table .fixed-table-footer tbody > tr > td {
  padding: 0 !important;
}

.bootstrap-table .fixed-table-footer .table {
  border-bottom: none;
  border-radius: 0;
  padding: 0 !important;
}

.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

p.fixed-table-scroll-inner {
  width: 100%;
  height: 200px;
}

div.fixed-table-scroll-outer {
  top: 0;
  left: 0;
  visibility: hidden;
  width: 200px;
  height: 150px;
  overflow: hidden;
}

.fixed-table-container .bs-checkbox,
.fixed-table-container .no-records-found {
  text-align: center;
}

.fixed-table-body thead th .th-inner,
.table td,
.table th {
  box-sizing: border-box;
}

.bootstrap-table .table {
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ddd;
  border-collapse: collapse !important;
  border-radius: 1px;
}

.bootstrap-table .table:not(.table-condensed),
.bootstrap-table .table:not(.table-condensed) > tbody > tr > td,
.bootstrap-table .table:not(.table-condensed) > tbody > tr > th,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > td,
.bootstrap-table .table:not(.table-condensed) > tfoot > tr > th,
.bootstrap-table .table:not(.table-condensed) > thead > tr > td {
  padding: 8px;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td,
.bootstrap-table .table.table-no-bordered > thead > tr > th {
  border-right: 2px solid transparent;
}

.bootstrap-table .table.table-no-bordered > tbody > tr > td:last-child {
  border-right: none;
}

.fixed-table-container {
  position: relative;
  clear: both;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.fixed-table-container.table-no-bordered {
  border: 1px solid transparent;
}

.fixed-table-footer,
.fixed-table-header {
  overflow: hidden;
}

.fixed-table-footer {
  border-top: 1px solid #ddd;
}

.fixed-table-body {
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
}

.fixed-table-container table {
  width: 100%;
}

.fixed-table-container thead th {
  height: 0;
  padding: 0;
  margin: 0;
  border-left: 1px solid #ddd;
}

.fixed-table-container thead th:focus {
  outline: transparent solid 0;
}

.fixed-table-container thead th:first-child {
  border-left: none;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.fixed-table-container tbody td .th-inner,
.fixed-table-container thead th .th-inner {
  padding: 8px;
  line-height: 24px;
  vertical-align: top;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-table-container thead th .sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.fixed-table-container thead th .both {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC');
}

.fixed-table-container thead th .asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}

.fixed-table-container thead th .desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}

.fixed-table-container th.detail {
  width: 30px;
}

.fixed-table-container tbody td {
  border-left: 1px solid #ddd;
}

.fixed-table-container tbody tr:first-child td {
  border-top: none;
}

.fixed-table-container tbody td:first-child {
  border-left: none;
}

.fixed-table-container tbody .selected td {
  background-color: #f5f5f5;
}

.fixed-table-container .bs-checkbox .th-inner {
  padding: 8px 0;
}

.fixed-table-container input[type='radio'],
.fixed-table-container input[type='checkbox'] {
  margin: 0 auto !important;
}

.fixed-table-pagination .pagination-detail,
.fixed-table-pagination div.pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fixed-table-pagination div.pagination .pagination {
  margin: 0;
}

.fixed-table-pagination .pagination a {
  padding: 6px 12px;
  line-height: 1.428571429;
}

.fixed-table-pagination .pagination-info {
  line-height: 34px;
  margin-right: 5px;
}

.fixed-table-pagination .btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.fixed-table-pagination .dropup .dropdown-menu {
  margin-bottom: 0;
}

.fixed-table-pagination .page-list {
  display: inline-block;
}

.fixed-table-toolbar .columns-left {
  margin-right: 5px;
}

.fixed-table-toolbar .columns-right {
  margin-left: 5px;
}

.fixed-table-toolbar .columns label {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
}

.fixed-table-toolbar .bs-bars,
.fixed-table-toolbar .columns,
.fixed-table-toolbar .search {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 34px;
}

.fixed-table-pagination li.disabled a {
  pointer-events: none;
  cursor: default;
}

.fixed-table-loading {
  display: none;
  position: absolute;
  top: 42px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  text-align: center;
}

.fixed-table-body .card-view .title {
  font-weight: 700;
  display: inline-block;
  min-width: 30%;
  text-align: left !important;
}

.table td,
.table th {
  vertical-align: middle;
}

.fixed-table-toolbar .dropdown-menu {
  text-align: left;
  max-height: 300px;
  overflow: auto;
}

.fixed-table-toolbar .btn-group > .btn-group {
  display: inline-block;
  margin-left: -1px !important;
}

.fixed-table-toolbar .btn-group > .btn-group > .btn {
  border-radius: 0;
}

.fixed-table-toolbar .btn-group > .btn-group:first-child > .btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fixed-table-toolbar .btn-group > .btn-group:last-child > .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bootstrap-table .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #ddd;
}

.bootstrap-table .table thead > tr > th {
  padding: 0;
  margin: 0;
}

.bootstrap-table .fixed-table-footer tbody > tr > td {
  padding: 0 !important;
}

.bootstrap-table .fixed-table-footer .table {
  border-bottom: none;
  border-radius: 0;
  padding: 0 !important;
}

.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

p.fixed-table-scroll-inner {
  width: 100%;
  height: 200px;
}

div.fixed-table-scroll-outer {
  top: 0;
  left: 0;
  visibility: hidden;
  width: 200px;
  height: 150px;
  overflow: hidden;
}

table.tableLinks tr,
table.tableLinks th,
table.tableLinks td {
  vertical-align: middle !important;
}

table.tableLinks .tableOptions {
  visibility: hidden;
}

table.tableLinks tr:hover .tableOptions {
  visibility: visible;
}

.page-item.active .page-link {
  z-index: 1;
  color: #ccc;
  background-color: transparent;
  border-color: #ccc;
  text-decoration: none;
}

.page-link:hover {
  color: #212529 !important;
}

.page-link {
  color: #55a4ab;
}

.react-bootstrap-table-page-btns-ul .page-item {
  font-weight: 700;
  font-size: 14px;
}

.react-bootstrap-table-page-btns-ul .disabled {
  opacity: 0.5;
}

tbody {
  border: 1px solid #ddd;
  font-size: 13px;
  font-family: 'montserrat';
}

.react-bs-container-header table {
  border-collapse: collapse;
  border-radius: 6px 6px 0 0 !important;
  overflow: hidden;
  border: 1px solid #666;
  background-color: #666;
}

.table thead th {
  border-bottom: none;
  border: 1px solid #b7b7b7;
  padding: 2px 20px;
  font-size: 14px;
}

.table th,
.table td {
  padding: 10px 20px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

th,
td {
  padding: 1em;
  background: #fff;
  border-bottom: none;
}

@media (max-width: 768px) {
  table.tableLinks .tableOptions {
    visibility: visible;
  }
}
