@charset "UTF-8";
/* MOBILE */
.separator {
  width: 100%;
  height: 1px;
  background-color: #dddddd; }

.react-bs-container-header table {
  background-color: transparent; }

.table-header {
  margin-top: 25px; }

.table-header table {
  border: none; }

.table-header tr {
  padding: 20px 10px 0px 10px; }

.table-header tr th,
tr.table-header th {
  font-family: 'Dosis', sans-serif;
  color: #aaaaaa;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.2em;
  border: none; }

.react-bs-container-body table tbody td {
  vertical-align: middle; }

.react-bs-container-body table tbody tr,
.react-bootstrap-table tbody tr {
  border-bottom: 1px solid #dddddd;
  color: #000;
  padding: 10px 10px;
  font-size: 14px; }

.react-bs-container-body table tbody tr:hover {
  background-color: #f6f6f6; }

.react-bootstrap-table tbody tr:hover {
  background-color: #f6f6f6; }

.react-bs-container-body table tbody {
  border: 0px solid transparent; }

.table-header .table thead th {
  vertical-align: inherit;
  border-bottom: 0px solid transparent; }

.table td,
.table th {
  padding: inherit;
  vertical-align: inherit;
  border-top: 0px solid transparent;
  background-color: transparent;
  /* display: inline-flex; */ }

.table td,
.table th:focus {
  outline: none; }

.react-bs-container-body table tbody .tr .numeroOs {
  font-size: 24px;
  font-weight: 500; }

.react-bs-container-body table tbody .tr .status {
  text-transform: uppercase; }

/* Navegação */
.react-bootstrap-table-page-btns-ul .page-item {
  font-family: 'Roboto', sans-serif;
  margin: 0px 3px; }

.page-item .page-link {
  color: #000;
  background-color: transparent;
  border-color: #ddd;
  text-decoration: none;
  padding: 5px 9px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px; }

.page-item.active .page-link {
  color: #ddd !important;
  background-color: transparent !important;
  border-color: #ddd !important;
  text-decoration: none;
  font-weight: 400;
  cursor: none; }

@media (max-width: 767px) {
  .react-bs-container-body table,
  .react-bs-container-body thead,
  .react-bs-container-body tbody,
  .react-bs-container-body th,
  .react-bs-container-body td,
  .react-bs-container-body tr {
    display: block; }
  .react-bs-container-body thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  .react-bs-container-body td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 30% !important;
    white-space: normal;
    text-align: left; }
  .react-bs-container-body td div:after {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    text-transform: none;
    color: #aaaaaa;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.1em; }
  .react-bs-container-body td:first-child {
    padding-top: 5px; }
  .react-bs-container-body td:last-child {
    padding-bottom: 5px; }
  .react-bs-container-body td:before {
    top: 5px; }
  .react-bs-container-body td div.colunaDestaque:after {
    top: 15px; }
  .react-bs-container-body td:before {
    content: attr(data-title); }
  .react-bs-container-body td div:after {
    content: attr(data-title); }
  .react-bs-container-body .table tbody tr {
    border-top: 1px solid #ddd; }
  .react-bs-container-body .table tbody tr td {
    border: none;
    padding: 3px; }
  .temSinal .react-bs-container-body .table tbody tr td:last-child {
    display: inline-flex;
    padding-left: 0px !important;
    vertical-align: sub; }
  .temSinal .react-bs-container-body .table tbody tr td:nth-last-child(2) {
    display: inline-flex; }
  .react-bs-container-body .table tbody tr:first-child {
    border-top: 0px solid #ddd; }
  .react-bs-container-header .table thead {
    display: none; } }

.react-bootstrap-table tbody {
  border: none; }
