/* ---- CADASTRO---- */

.cadastro {
  max-width: 940px;
  margin: auto;
}

.cadastro h3 {
  color: #aaa;
  margin: 40px 0;
}

.lineVert {
  border-bottom: 1px solid #ddd;
  margin-top: 30px;
}

/* MOBILE */

@media (max-width: 1024px) {
}
